/**
 * state and actions surrounding Tozny groups
 * @module groups
 */
import group from './group'
import tozny from '../../api/tozny'

/** submodules */
const modules = {
  group,
}

/** initial group state */
const state = {
  status: 'initializing',
  groups: [],
  errorMessage: '',
  selectedGroupID: null,
  selectedGroupName: null,
  initialized: false,
  nextToken: undefined,
  deleteQueue: {},
}

const statuses = [
  'initializing',
  'loading',
  'zero',
  'zero.adding',
  'idle',
  'idle.delete',
  'add',
  'adding',
  'globalError',
]

const getters = {
  accountHasGroups(state) {
    return !!state.groups.length
  },
  hasMore(state) {
    return state.nextToken !== 0
  },
  getGroup(state) {
    return (id) => state.groups[id]
  },
  selectedGroup(state, rootGetters) {
    let group = rootGetters['group/getGroup']
    if (group !== null) {
      return group
    }
    const filteredGroups = state.groups
      .slice()
      .filter((h) => h.groupID === state.selectedGroupID)
    return filteredGroups[0]
  },
  groupID(state) {
    return state.selectedGroupID
  },
}

const mutations = {
  INITIALIZED(state) {
    state.initialized = true
  },
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_GROUPS(state, { groups }) {
    state.groups = groups
  },
  SET_ERROR(state, { error, status }) {
    state.errorMessage = error
    state.status = status
  },
  CLEAR_ERROR(state) {
    state.errorMessage = ''
  },
  SET_NEXT_TOKEN(state, nextToken) {
    state.nextToken = nextToken
  },
  SET_SELECTED_GROUP(state, groupID) {
    state.selectedGroupID = groupID
  },
  SET_SELECTED_GROUP_NAME(state, groupName) {
    state.selectedGroupName = groupName
  },
  SET_DELETE_QUEUE(state, { group }) {
    state.deleteQueue = group
  },
  CLEAR_DELETE_QUEUE() {
    state.deleteQueue = {}
  },
}

const actions = {
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', 'Error: Unknown State', 'globalError')
    }
  },
  async initialize({ dispatch, state }) {
    if (state.initialized) {
      return
    }
    // Only act on initializing state
    if (state.status !== 'initializing') {
      return
    }
    if (!state.groups.length) {
      await dispatch('transitionStatus', 'loading')
      await dispatch('loadGroups')
    } else {
      await dispatch('transitionStatus', 'idle')
    }
  },
  async loadGroups({ commit, dispatch, state, rootGetters }) {
    try {
      const queenClient = rootGetters['account/queenClient']
      const groupPage = await tozny.listGroups(queenClient, state.nextToken)
      let groups = [...state.groups]
      for (let g of groupPage.groups) {
        let isSecret = g.groupName.includes('tozny.secret.')
        if (!isSecret) {
          groups.push(g)
        }
      }
      commit('SET_GROUPS', { groups })
      commit('SET_NEXT_TOKEN', groupPage.nextToken)
      const transition = groupPage.groups.length ? 'idle' : 'zero'
      await dispatch('transitionStatus', transition)
    } catch (e) {
      const error = 'Backend server error trying to list groups.'
      commit('SET_ERROR', { error, status: 'globalError' })
    }
  },
  async selectGroup({ commit }, groupID) {
    commit('SET_SELECTED_GROUP', groupID)
  },
  async createGroup({ commit, dispatch, rootGetters }, group) {
    const queenClient = rootGetters['account/queenClient']
    try {
      let writtenGroup = await tozny.createGroup(queenClient, group)
      let isSecret = writtenGroup.group.groupName.includes('tozny.secret.')
      const groups = [...state.groups]
      if (!isSecret) {
        groups.push(writtenGroup.group)
      }
      commit('SET_GROUPS', { groups })
      dispatch('transitionStatus', 'idle')
    } catch (e) {
      const status = state.status === 'zero.adding' ? 'zero' : 'idle'
      let error = 'Backend server error trying to add a group.'
      if (e.message === 'Conflict') {
        error =
          'Conflict: A group with this name already exists for this account.'
      }
      commit('SET_ERROR', { error, status })
    }
  },
  selectedGroupID({ commit, state }) {
    const filteredGroups = state.groups
      .slice()
      .filter((h) => h.groupName === state.selectedGroupName)
    commit('SET_SELECTED_GROUP', filteredGroups[0].groupID)
  },
  async selectGroupName({ commit, dispatch }, groupName) {
    commit('SET_SELECTED_GROUP_NAME', groupName)
    dispatch('selectedGroupID')
  },
  async deleteGroup({ commit, dispatch, state, rootGetters }, group) {
    const original = state.groups.slice(0)
    const modified = state.groups.slice(0).filter((g) => g !== group)
    commit('SET_GROUPS', { groups: modified })
    const transition = modified.length > 0 ? 'idle' : 'zero'
    await dispatch('transitionStatus', transition)
    try {
      const queenClient = rootGetters['account/queenClient']
      await tozny.deleteGroup(queenClient, group.groupID)
    } catch (e) {
      commit('SET_GROUPS', { groups: original })
      const error = 'Deleting group ' + group.groupName + ' failed.'
      commit('SET_ERROR', { error, status: 'idle' })
    }
  },
  async enqueueDelete({ commit, dispatch }, group) {
    commit('SET_DELETE_QUEUE', { group })
    await dispatch('transitionStatus', 'idle.delete')
  },
  async cancelDeleteQueue({ commit, dispatch }) {
    commit('CLEAR_DELETE_QUEUE')
    await dispatch('transitionStatus', 'idle')
  },
}

export default {
  namespaced: true,
  modules,
  state,
  actions,
  getters,
  mutations,
}
