/**
 * @module groupMembers
 */
import { types } from '@toznysecure/sdk/browser'
import tozny from '../../api/tozny'
import group from './group'

let activeGroup = undefined
const statuses = [
  'initializing',
  'error',
  'active',
  'loading',
  'idle',
  'idle.delete',
  'globalError',
  'add',
]

/** initial group state */
const state = {
  status: 'initializing',
  group: {},
  groupMembers: [],
  errorMessage: '',
  currentGroupID: '',
  selectedGroupMember: '',
  nextToken: undefined,
  deleteQueue: [],
}

const getters = {
  storage(state) {
    if (!(state.status === 'active' && activeGroup)) {
      return undefined
    }
    return activeGroup
  },
  getGroup(state, rootGetters) {
    let group = rootGetters['group/getGroup']
    if (group !== null) {
      return group
    }
    return group
  },
  getGroupMembers(state) {
    return state.groupMembers
  },
  selectedGroupMember(state) {
    const filteredMembers = state.groupMembers
      .slice()
      .filter((h) => h.clientId === state.selectedGroupMember)
    return filteredMembers[0]
  },
  hasMore(state) {
    return state.nextToken !== 0
  },
}

const mutations = {
  INITIALIZED(state) {
    state.initialized = true
  },
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_GROUP_ID(state, groupID) {
    state.currentGroupID = groupID
  },
  SET_ERROR(state, { message, status }) {
    state.errorMessage = message
    state.status = status
  },
  CLEAR_ERROR(state) {
    state.errorMessage = ''
  },
  CLEAR(state) {
    group.getters.getGroup = {}
    state.currentGroupID = group.state.groupID
    state.groupMembers = []
    state.errorMessage = ''
    activeGroup = undefined
  },
  ADD_GROUPMEMBERS(state, groupMembers) {
    state.groupMembers = [...state.groupMembers, ...groupMembers]
  },
  SET_SELECTED_GROUP_MEMBER(state, clientId) {
    state.selectedGroupMember = clientId
  },
  SET_DELETE_QUEUE(state, groupMembers) {
    state.deleteQueue.push(groupMembers)
  },
  CLEAR_DELETE_QUEUE() {
    state.deleteQueue = []
  },
  SET_GROUPMEMBERS(state, { groupMembers }) {
    state.groupMembers = groupMembers
  },
  CLEAR_GROUPMEMBERS(state) {
    state.groupMembers = []
  },
}

/** asynchronous actions against the module store and Tozny record list level resources */
const actions = {
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', 'Error: Unknown State', 'error')
    }
  },
  async initializeGroupMembers({ commit, dispatch }) {
    await dispatch('transitionStatus', 'idle')
    await dispatch('listMembers')
    const fetchedMembers = state.groupMembers
    if (!fetchedMembers) {
      commit('SET_ERROR', {
        message: 'No Group Members found. If this wrong, reload the page',
        status: 'error',
      })
      return
    }
    await dispatch('transitionStatus', 'idle')
  },
  async addGroupMembers({ commit, dispatch, rootGetters }, { groupMembers }) {
    await dispatch('transitionStatus', 'add')
    try {
      let groupID = group.state.group.groupID
      const queenClient = rootGetters['account/queenClient']
      const gM = []
      for (let groupMember of groupMembers) {
        var capabilitiesGiven = new Object()
        if (groupMember.capabilities.includes('READ_CONTENT')) {
          capabilitiesGiven['read'] = true
        }
        if (groupMember.capabilities.includes('SHARE_CONTENT')) {
          capabilitiesGiven['share'] = true
        }
        if (groupMember.capabilities.includes('MANAGE_MEMBERSHIP')) {
          capabilitiesGiven['manage'] = true
        }
        var groupM = new types.GroupMember(
          groupMember.clientId,
          capabilitiesGiven
        )
        gM.push(groupM)
      }
      const newGroupMembers = await tozny.addGroupMembers(
        queenClient,
        groupID,
        gM
      )
      commit('ADD_GROUPMEMBERS', newGroupMembers)
      await dispatch('transitionStatus', 'idle')
    } catch (e) {
      commit('SET_ERROR', {
        message: 'Backend server error trying to add group members.',
        status: 'error',
      })
    }
  },
  async selectGroupMember({ commit, dispatch }, clientId) {
    commit('SET_SELECTED_GROUP_MEMBER', clientId)
    dispatch('selectedGroupID')
  },
  async deleteMembers({ commit, dispatch, state, rootGetters }) {
    const groupMembersToDelete = state.deleteQueue
    const original = [...state.groupMembers]
    const newList = [...state.groupMembers].filter(
      (member) => member.client_id != groupMembersToDelete
    )
    commit('CLEAR_GROUPMEMBERS')
    let groupID = group.state.group.groupID
    commit('SET_GROUPMEMBERS', { groupMembers: newList })
    try {
      const queenClient = rootGetters['account/queenClient']
      await tozny.deleteMembers(queenClient, groupID, groupMembersToDelete)
      commit('CLEAR_DELETE_QUEUE')
      await dispatch('transitionStatus', 'idle')
    } catch (e) {
      commit('SET_GROUPMEMBERS', { groupMembers: original })
      const error = 'Deleting groupMembers for' + groupID + ' failed.'
      commit('SET_ERROR', { error, status: 'idle' })
    }
  },
  async listMembers({ commit, dispatch, state, rootGetters }) {
    try {
      await dispatch('transitionStatus', 'idle')
      const queenClient = rootGetters['account/queenClient']
      let groupID = group.state.group.groupID
      let memberEndpointReturn = await tozny.listMembers(queenClient, groupID)
      commit('CLEAR_GROUPMEMBERS')
      let groupMembers = [
        ...state.groupMembers,
        ...memberEndpointReturn.group_members,
      ]
      while (memberEndpointReturn.next_token != 0) {
        memberEndpointReturn = await tozny.listMembers(
          queenClient,
          groupID,
          memberEndpointReturn.next_token
        )
        groupMembers = [
          ...state.groupMembers,
          ...memberEndpointReturn.group_members,
        ]
      }
      commit('SET_GROUPMEMBERS', { groupMembers })
      await dispatch('transitionStatus', 'idle')
    } catch (e) {
      const error = 'Backend server error trying to list group members'
      commit('SET_ERROR', { error, status: 'globalError' })
    }
  },
  async enqueueDelete({ commit, dispatch }, groupMember) {
    commit('SET_DELETE_QUEUE', groupMember)
    await dispatch('transitionStatus', 'idle.delete')
  },
  async cancelDeleteQueue({ commit, dispatch }) {
    commit('CLEAR_DELETE_QUEUE')
    await dispatch('transitionStatus', 'idle')
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
