/**
 * State associated with active mfa.
 */

/**
 * state and actions surrounding identity mfa
 * @module mfa
 */

import qrCode from 'qrcode-generator'
import { checkStatus, handleMFAError, isUnauthorized } from '../../utils/utils'
import tozny from '../../api/tozny'

const statuses = ['initializing', 'loading', 'idle', 'idle.submitting', 'error']

/** initial session state */
const state = {
  /** the account's current billing status */
  status: 'initializing',
  type: 'none',
  secret: '',
  qrCode: '',
  deviceRegistration: '',
  pushQRReady: false,
  policy: {},
  errorMessage: '',

  /**
   * HARDARE DEVICE MFA STATE
   */
  mfaTOTPDevices: [],
  webauthnErrorMessage: '',
  mfaWebAuthnDevices: [],
}
/** cache-able getters for the current mfa for the active identity */
const getters = {
  hasError: (state) => {
    return !!state.errorMessage
  },
  isIdle: (state) => {
    return state.status.substring(0, 4) === 'idle'
  },
  pushQRReady: (state) => {
    return state.pushQRReady
  },
  qrCodeImg: (state) => {
    const qr = qrCode(0, 'L')
    qr.addData(state.qrCode)
    qr.make()
    return qr
      .createSvgTag(100, 0)
      .replace(/(<svg .*?)( width=".*?" height=".*?")/, '$1')
  },
  pushQRCodeImg: (state) => {
    const qr = qrCode(0, 'L')
    qr.addData(state.deviceRegistration)
    qr.make()
    return qr
      .createSvgTag(100, 0)
      .replace(/(<svg .*?)( width=".*?" height=".*?")/, '$1')
  },
}

/** synchronous mutations of tokens state */
const mutations = {
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_DEVICE_REGISTRATION(state, reg) {
    state.deviceRegistration = reg
    state.pushQRReady = true
  },
  CLEAR_DEVICE_REGISTRATION(state) {
    state.deviceRegistration = ''
    state.pushQRReady = false
  },
  SET_MFA(state, mfa) {
    state.type = mfa.type
    // If type is set, MFA is configured, do not take in secrets, etc.
    if (state.type !== 'none') {
      state.secret = ''
      state.qrCode = ''
      state.policy = {}
      return
    }
    if (mfa.secret) {
      state.secret = mfa.secret
    }
    if (mfa.qrCode) {
      state.qrCode = mfa.qrCode
    }
    if (mfa.policy) {
      state.policy = mfa.policy
    }
  },
  SET_ERROR(state, { error, status }) {
    state.errorMessage = error
    state.status = status
  },
  CLEAR_MFA(state) {
    state.type = 'none'
    state.secret = ''
    state.qrCode = ''
    state.policy = {}
  },
  CLEAR_ERROR(state) {
    state.errorMessage = ''
  },
  SET_MFA_DEVICES(state, devices) {
    state.mfaTOTPDevices = devices.filter(
      (device) => device.credentialType === 'totp'
    )
    state.mfaWebAuthnDevices = devices.filter(
      (device) => device.credentialType === 'webauthn'
    )
  },
  SET_WEBAUTHN_ERROR(state, error) {
    state.webauthnErrorMessage = error
  },
}

/**
 * Callable state transitions that facilitate async actions and state transitions
 */
const actions = {
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', { error: 'Error: Unknown state', status: 'error' })
    }
  },
  /**
   * Syncs the state machine with the mfa state, fetching as necessary.
   * @param {context} param0 The vuex state context
   */
  async initialize({ dispatch }) {
    // Only act on initializing state
    if (state.status !== 'initializing') {
      return
    }
    // Determine if initial fetch is necessary
    if (!state.type !== 'none' || state.secret !== '') {
      await dispatch('transitionStatus', 'loading')
      await dispatch('loadMfa')
    } else {
      await dispatch('transitionStatus', 'idle')
    }
  },
  async loadMfa({ commit, dispatch, rootState }) {
    try {
      const mfa = await tozny.getMFA(rootState.account.accountClient)
      commit('SET_MFA_DEVICES', mfa)
      if (mfa.filter((device) => device.credentialType === 'totp').length > 0) {
        commit('SET_MFA', { type: 'totp' })
        await dispatch('transitionStatus', 'idle')
      } else {
        const qrInfo = await tozny.getQRInfo(rootState.account.accountClient)
        commit('SET_MFA', qrInfo)
        await dispatch('transitionStatus', 'idle')
      }
    } catch (e) {
      const error = e.message
      commit('SET_ERROR', { error, status: 'error' })
    }
  },
  async setupTotp({ commit, dispatch, rootState, state }, totp) {
    commit('CLEAR_ERROR')
    await dispatch('transitionStatus', 'idle.submitting')
    try {
      const data = {
        secret: state.secret,
        totp,
      }
      if (totp === '') {
        throw new Error('You must enter a one-time code')
      }
      if (totp.length != state.policy.digits) {
        throw new Error(
          `One-time code should be ${state.policy.digits} digits long`
        )
      }
      const request = await tozny.registerTotp(
        rootState.account.accountClient,
        data
      )
      checkStatus(request, `Unable to complete setup: ${request.error}`)
      commit('SET_MFA', { type: 'totp' })
      await dispatch('loadMfa')
      await dispatch('transitionStatus', 'idle')
    } catch (e) {
      const error = e.message
      commit('SET_ERROR', { error, status: 'idle' })
    }
  },
  async removeMFA({ commit, dispatch, rootState }, credId) {
    await dispatch('transitionStatus', 'loading')
    try {
      const request = await tozny.deleteMFA(
        rootState.account.accountClient,
        credId
      )
      checkStatus(request, `Unable to complete removal: ${request.statusText}`)
      commit('CLEAR_DEVICE_REGISTRATION')
      throw new Error('Successfully removed your MFA!')
    } catch (e) {
      const error = e.message
      commit('SET_ERROR', { error, status: 'error' })
    }
  },
  async removeWebAuthn({ commit, dispatch, rootState }, credId) {
    try {
      await tozny.deleteMFA(rootState.account.accountClient, credId)
      await dispatch('loadMfa')
    } catch (e) {
      const error = e.message
      commit('SET_WEBAUTHN_ERROR', error)
      commit('SET_ERROR', { error, status: 'idle' })
    }
  },
  async reset({ commit, dispatch }) {
    await dispatch('transitionStatus', 'initializing')
    commit('CLEAR_MFA')
    commit('CLEAR_ERROR')
    commit('SET_WEBAUTHN_ERROR')
  },
  async reload({ dispatch }) {
    await dispatch('reset')
    await dispatch('initialize')
  },

  /**
   * registerWebAuthnDevice sends the initiate WebAuthn challenge request to the API,
   * builds the object representing a WebAuthn user credential, and sends the data to
   * be registered to the API.
   */
  async registerWebAuthnDevice({ commit, dispatch, rootState }, deviceName) {
    commit('SET_WEBAUTHN_ERROR', '')
    if (deviceName === '') {
      commit(
        'SET_WEBAUTHN_ERROR',
        handleMFAError(
          new Error('You must enter authenticator name.'),
          'You must enter authenticator name.'
        )
      )
      return false
    }
    try {
      const challengeData = await tozny.initiateWebAuthn(
        rootState.account.accountClient
      )
      const registrationData = await navigator.credentials.create({
        publicKey: challengeData.toPublicKeyCredentialCreationOptions(),
      })
      const request = await tozny.registerWebAuthnDevice(
        rootState.account.accountClient,
        registrationData,
        deviceName
      )
      checkStatus(request, `Unable to register device: ${request.statusText}`)
      await dispatch('loadMfa')
    } catch (e) {
      commit(
        'SET_WEBAUTHN_ERROR',
        handleMFAError(
          e,
          'Something went wrong. Unable to register hardware device.'
        )
      )
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      }
      return false
    }
  },
}

export default {
  // namespace this modules actions, mutations, and getters under 'mfa' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
