/**
 * billing implements user level actions of Tozny billing level resources
 * @module billing
 */
import tozny from '../../api/tozny'
import moment from 'moment'

/** initial billing state */
const state = {
  /** the account's current billing status */
  billingStatus: null,
  couponErrorMessage: '',
  couponSuccessMessage: '',
  couponLoading: false,
}

/** cache-able getters for the current users Tozny billing level resources */
const getters = {
  billingStatus: (state) => {
    return state.billingStatus
  },
  daysLeftInFreeTrial: (state) => {
    let daysLeftInTrial = null
    if (state.billingStatus && state.billingStatus.isTrial) {
      const currentDate = moment()
      const dateString = state.billingStatus.trialPeriodEnds
      const trialDate = moment(dateString)
      const daysLeft = trialDate.diff(currentDate, 'days')
      daysLeftInTrial = daysLeft
    }
    return daysLeftInTrial
  },
  isTrial: (state) => {
    return !!state.billingStatus && state.billingStatus.isTrial
  },
  trialPeriodEnds: (state) => {
    if (state.billingStatus) {
      return state.billingStatus.trialPeriodEnds
    }
  },
  accountActive: (state) => {
    return state.billingStatus ? state.billingStatus.accountActive : null
  },
  isFree: (state) => {
    return !!state.billingStatus && state.billingStatus.isFree
  },
}

/** synchronous mutations of billing state */
const mutations = {
  SET_STATUS(state, { billingStatus }) {
    state.billingStatus = billingStatus
  },
  SET_COUPON_ERROR(state, error) {
    state.couponErrorMessage = error
  },
  CLEAR_COUPON_ERROR(state) {
    state.couponErrorMessage = ''
  },
  SET_COUPON_SUCCESS(state) {
    state.couponSuccessMessage = 'Coupon successfully applied!'
  },
  CLEAR_COUPON_SUCCESS(state) {
    state.couponSuccessMessage = ''
  },
  TOGGLE_COUPON_LOADING(state) {
    state.couponLoading = !state.couponLoading
  },
}

/** asynchronous actions against the module store and Tozny billing level resources */
const actions = {
  /**
   * checkValidBilling checks if the billing status for the current account is valid
   * returning boolean to indicate validity. An account is considered to have valid billing
   * if the account is active and in good standing.
   */
  async checkValidBilling({ commit, rootState }) {
    const accountClient = rootState.account.accountClient
    const billingStatus = await tozny.checkAccountStatus(accountClient)
    commit('SET_STATUS', { billingStatus })
    return billingStatus.accountActive && billingStatus.isGoodStanding
  },
  async applyCouponCode({ commit, rootState }, couponCode) {
    const accountClient = rootState.account.accountClient
    commit('CLEAR_COUPON_ERROR')
    commit('TOGGLE_COUPON_LOADING')
    try {
      await tozny.applyCouponCode(accountClient, couponCode)
      commit('SET_COUPON_SUCCESS')
      setTimeout(() => {
        commit('CLEAR_COUPON_SUCCESS')
      }, 5000)
      commit('TOGGLE_COUPON_LOADING')
    } catch (error) {
      const errorMsg =
        `Could not accept coupon. Error:  ${error.message}` ||
        'Could not accept coupon.'
      commit('SET_COUPON_ERROR', errorMsg)
      commit('TOGGLE_COUPON_LOADING')
    }
  },
  async subscribe({ dispatch, rootState }) {
    const accountClient = rootState.account.accountClient
    try {
      await tozny.subscribe(accountClient)
      await dispatch('checkValidBilling')
      /*
        There is a billing object in the accountClient.
        The services will likely be further merged in the future.
        Keep an eye for bugs that may indicate that the account profile with billing needs refresh.
      */
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  },
  async unsubscribe({ dispatch, rootState }) {
    const accountClient = rootState.account.accountClient
    try {
      await tozny.unsubscribe(accountClient)
      await dispatch('checkValidBilling')
      /*
        There is a billing object in the accountClient.
        The services will likely be further merged in the future.
        Keep an eye for bugs that may indicate that the account profile with billing needs refresh.
      */
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  },
}

export default {
  // namespace this modules actions, mutations, and getters under '/billing' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
