import router from '@/router/router'

/**
 * nav implements state for nav menu
 * @module nav
 */

/** initial nav state */
const state = {
  menuTozstoreOpen: false,
  menuTozidOpen: false,
  menuSettingsOpen: false,
}

/** computed values for nav state */
const getters = {
  activeNavItemPath: () => {
    return router.currentRoute.path
  },
}

/** synchronous mutations of nav state */
const mutations = {
  TOGGLE_MENU_TOZSTORE_OPEN(state) {
    state.menuTozstoreOpen = !state.menuTozstoreOpen
  },
  TOGGLE_MENU_TOZID_OPEN(state) {
    state.menuTozidOpen = !state.menuTozidOpen
  },
  TOGGLE_MENU_SETTINGS_OPEN(state) {
    state.menuSettingsOpen = !state.menuSettingsOpen
  },
}

/** synchronous actions */

const actions = {
  toggleTozstoreMenuOpen({ commit }) {
    commit('TOGGLE_MENU_TOZSTORE_OPEN')
  },
  toggleTozIdMenuOpen({ commit }) {
    commit('TOGGLE_MENU_TOZID_OPEN')
  },
  toggleSettingsMenuOpen({ commit }) {
    commit('TOGGLE_MENU_SETTINGS_OPEN')
  },
}

export default {
  // namespace this modules actions, mutations, and getters under '/nav' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
