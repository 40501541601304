/**
 * Implements user level actions of Tozny account level resources.
 * @module account
 */

import { Account as AccountService } from '@toznysecure/account-sdk'
import Tozny from '@toznysecure/sdk/browser'
import tozny from '../../api/tozny'

/** Network address of the Tozny API to use for making requests */
const TOZNY_API_HOST = global.API_URL
/**  global account service object */
const accountService = new AccountService(Tozny, TOZNY_API_HOST)

/** initial account state */
const state = {
  /** the account client object for the current account */
  accountClient: null,
  /** the key to use for recovering access to the account and resources in the event of lost password */
  paperKey: '',
  changeProfileErrorMessage: '',
  changeProfileSuccessMessage: '',
  changeProfileLoading: false,
  changePasswordErrorMessage: '',
  changePasswordSuccessMessage: '',
  changePasswordLoading: false,
  updateBillingLoading: false,
  updateBillingErrorMessage: '',
  paperKeySuccessMessageForLogin: false,
  loginFlow: 'Password',
  loginContext: {},
  mfaKeys: '',
  mfaTypes: [],
}

/** cache-able getters for the current users Tozny account level resources */
const getters = {
  queenClient: (state) => {
    if (state.accountClient === null) {
      return null
    }
    return state.accountClient.queenClient
  },
  accountName: (state) => {
    if (state.accountClient === null) {
      return null
    }
    return state.accountClient.profile.name
  },
  accountEmail: (state) => {
    if (state.accountClient === null) {
      return null
    }
    return state.accountClient.profile.email
  },
  emailVerified: (state) => {
    if (state.accountClient === null) {
      return null
    }
    return state.accountClient.profile.verified
  },
  payingCustomer: (state) => {
    if (state.accountClient === null) {
      return null
    }
    return !!state.accountClient.account.billing.cc_last4
  },
  billingInfo: (state) => {
    if (state.accountClient === null) {
      return null
    }
    return state.accountClient.account.billing
  },
  accountId: (state) => {
    if (state.accountClient === null) {
      return null
    }
    return state.accountClient.profile.id
  },
  loginFlow: (state) => state.loginFlow,
}

/** synchronous mutations of account state */
const mutations = {
  SET_PAPERKEY(state, { paperKey }) {
    state.paperKey = paperKey
  },
  SET_ACCOUNT_CLIENT(state, { accountClient }) {
    state.accountClient = accountClient
  },
  CLEAR_ACCOUNT_CLIENT(state) {
    state.accountClient = null
  },
  SET_NEW_ACCOUNT_AND_PROFILE(state, { account, profile }) {
    state.accountClient.account = account
    state.accountClient.profile = profile
  },
  SET_CHANGE_PROFILE_ERROR(state, error) {
    state.changeProfileErrorMessage = error
  },
  SET_CHANGE_PROFILE_SUCCESS_MESSAGE(state) {
    state.changeProfileSuccessMessage = 'Profile successfully changed!'
  },
  CLEAR_CHANGE_PROFILE_SUCCESS_MESSAGE(state) {
    state.changeProfileSuccessMessage = ''
  },
  CLEAR_CHANGE_PROFILE_ERROR(state) {
    state.changeProfileErrorMessage = ''
  },
  TOGGLE_CHANGE_PROFILE_LOADING(state) {
    state.changeProfileLoading = !state.changeProfileLoading
  },
  SET_CHANGE_PASSWORD_ERROR(state, error) {
    state.changePasswordErrorMessage = error
  },
  CLEAR_CHANGE_PASSWORD_ERROR(state) {
    state.changePasswordErrorMessage = ''
  },
  SET_CHANGE_PASSWORD_SUCCESS(state) {
    state.changePasswordSuccessMessage = 'Password successfully changed!'
  },
  CLEAR_CHANGE_PASSWORD_SUCCESS(state) {
    state.changePasswordSuccessMessage = ''
  },
  TOGGLE_CHANGE_PASSWORD_LOADING(state) {
    state.changePasswordLoading = !state.changePasswordLoading
  },
  SET_UPDATE_BILLING_ERROR(state, error) {
    state.updateBillingErrorMessage = error
  },
  CLEAR_UPDATE_BILLING_ERROR(state) {
    state.updateBillingErrorMessage = ''
  },
  TOGGLE_UPDATE_BILLING_LOADING(state) {
    state.updateBillingLoading = !state.updateBillingLoading
  },
  PAPERKEY_SUCCESS_TRUE(state) {
    state.paperKeySuccessMessageForLogin = true
  },
  PAPERKEY_SUCCESS_FALSE(state) {
    state.paperKeySuccessMessageForLogin = false
  },
  SET_LOGIN_ACTION(state, data) {
    state.loginFlow = data.type
    state.loginContext = data.context
    state.mfaTypes = data.mfaTypes
  },
  SET_MFA_KEYS(state, keys) {
    state.mfaKeys = keys
  },
  SET_LOGIN_FLOW(state, type) {
    state.loginFlow = type
  },
}

/** asynchronous actions against the module store and Tozny account level resources */
const actions = {
  /** Clear the error message */
  async clearChangePasswordErrorMessage({ commit }) {
    commit('CLEAR_CHANGE_PASSWORD_ERROR')
  },

  /** registerAndBackupAccount registers a new account for accessing Tozny services via the UI or API
   *
   *  Displays error to user if
   *     account email is invalid
   *     account registration fails
   *     account service is down
   *     account service returned registration response is invalid json
   * @param {object} newAccount - user defined account creation information
   *         {
   *           email: 'string',
   *           password: 'string', //account password used for login
   *           accountName: 'string'
   *         }
   * @returns {string} paper_key account recovery paper key
   */
  async registerAndBackupAccount({ dispatch, commit, rootState }, account) {
    // Clear and set loading state
    commit('CLEAR_ERROR', null, { root: true })
    commit('TOGGLE_LOADING', null, { root: true })
    try {
      if (rootState.registrationDisabled) {
        throw new Error('Registration is disabled')
      }
      const registrationResponse = await tozny.registerAndBackupAccount(
        accountService,
        account
      )
      const paperKey = registrationResponse.paperKey
      commit('SET_PAPERKEY', { paperKey })
      // Persist account client to session storage and the app store
      const accountClient = registrationResponse.client
      commit('SET_ACCOUNT_CLIENT', { accountClient })
      await dispatch('loadAccountClientToSession', accountClient)
      // Clear loading state
      commit('TOGGLE_LOADING', null, { root: true })
      await dispatch('updateIntercom')
      return true
    } catch (error) {
      // Show the user the registration error
      commit('SET_ERROR', error.message, { root: true })
      // Clear loading state
      commit('TOGGLE_LOADING', null, { root: true })
    }
  },
  /**
   * login logins the user using the provided account credentials.
   * If login is successful
   *     the client credentials and account metadata for the account will be persisted to local storage
   *     the user will be redirected to the Dashboard landing page.
   * Displays error if
   *     the login credentials are invalid
   *     the account service is down
   *     the returned login response is invalid json
   * @param {object} account - account credentials
   *         {
   *           email: 'string',
   *           password: 'string'
   *         }
   * @returns loginResponse {obj@tozny/account-sdk.Client} account queen client
   */
  async login({ dispatch, commit }, account) {
    // Clear and set loading state
    commit('CLEAR_ERROR', null, { root: true })
    commit('TOGGLE_LOADING', null, { root: true })
    try {
      const pwdChallege = await tozny.login(accountService, account)
      commit('TOGGLE_LOADING', null, { root: true })
      if (
        pwdChallege.profile.loginAction !== '' &&
        pwdChallege.profile.loginAction !== 'continue'
      ) {
        const mfaTypes = pwdChallege.profile.loginAction.split(',')
        const mfaFlowType = mfaTypes.length ? mfaTypes[0] : mfaTypes
        const context = pwdChallege.profile.context
        context.challenge = pwdChallege.profile.challenge
        commit('SET_LOGIN_ACTION', { type: mfaFlowType, context, mfaTypes })
        commit('SET_MFA_KEYS', {
          username: account.email,
          sigKeys: pwdChallege.sigKeys,
          encKey: pwdChallege.encKey,
          challenge: pwdChallege.profile.challenge,
        })
        return false
      } else {
        const accountClient = await tozny.completeLogin(
          accountService,
          account.email,
          pwdChallege.sigKeys,
          pwdChallege.encKey,
          pwdChallege.profile
        )
        return await dispatch('storeAccountClient', accountClient)
      }
    } catch (error) {
      console.log(error)
      // Show the user the returned error
      commit('SET_ERROR', 'Username or password incorrect.', { root: true })
      // Clear loading state
      commit('TOGGLE_LOADING', null, { root: true })
    }
  },

  async verifyTotp({ state, dispatch, commit }, data) {
    try {
      const profile = await tozny.verifyTotp(
        accountService,
        state.mfaKeys.username,
        state.mfaKeys.sigKeys,
        state.mfaKeys.challenge,
        data.totp
      )
      const accountClient = await tozny.completeLogin(
        accountService,
        state.mfaKeys.username,
        state.mfaKeys.sigKeys,
        state.mfaKeys.encKey,
        profile
      )
      return await dispatch('storeAccountClient', accountClient)
    } catch (e) {
      console.log(e)
      commit('SET_ERROR', 'Invalid OTP', { root: true })
      if (e.response) {
        e.response.json().then((e) => {
          commit('SET_ERROR', e.error, { root: true })
        })
      }
    }
  },

  async verifyWebAuthn({ state, dispatch, commit }, data) {
    commit('CLEAR_ERROR', null, { root: true })
    commit('TOGGLE_LOADING', null, { root: true })
    try {
      const profile = await tozny.verifyWebAuthn(
        accountService,
        state.mfaKeys.username,
        data
      )
      const accountClient = await tozny.completeLogin(
        accountService,
        state.mfaKeys.username,
        state.mfaKeys.sigKeys,
        state.mfaKeys.encKey,
        profile
      )
      return await dispatch('storeAccountClient', accountClient)
    } catch (e) {
      commit('TOGGLE_LOADING', null, { root: true })
      if (e.response) {
        e.response.json().then((ex) => {
          console.log(ex)
          commit('SET_ERROR', ex.error, { root: true })
        })
      }
    }
  },

  changeLoginFlow({ commit }, type) {
    commit('SET_LOGIN_FLOW', type)
  },

  async storeAccountClient({ dispatch, commit }, accountClient) {
    // Persist account client object for future account api calls to session and app store
    await dispatch('loadAccountClientToSession', accountClient)
    commit('SET_ACCOUNT_CLIENT', { accountClient })
    // Clear loading state
    commit('TOGGLE_LOADING', null, { root: true })
    await dispatch('updateIntercom')
    return true
  },

  async resetMFA({ commit }, account) {
    commit('CLEAR_ERROR', null, { root: true })
    commit('TOGGLE_LOADING', null, { root: true })
    try {
      const reset = await tozny.resetMFA(accountService, account)
      commit('TOGGLE_LOADING', null, { root: true })
      if (reset.status === 200) {
        return true
      } else {
        commit('SET_ERROR', 'Username or paper key incorrect.', { root: true })
      }
    } catch (e) {
      console.log(e)
      commit('SET_ERROR', 'Username or paper key incorrect.', { root: true })
      commit('TOGGLE_LOADING', null, { root: true })
    }
  },

  /**
   * login with paper key logins the user using account credentials with paperkey instead of password
   * If login is successful
   *     the client credentials and account metadata for the account will be persisted to local storage
   *     the user will be redirected to the Dashboard landing page.
   * Displays error if
   *     the login credentials are invalid
   *     the account service is down
   *     the returned login response is invalid json
   * @param {object} account - account credentials
   *         {
   *           email: 'string',
   *           password: 'string'
   *         }
   * @returns loginResponse {obj@tozny/account-sdk.Client} account queen client
   */
  async loginWithPaperKey({ dispatch, commit }, account) {
    // Clear and set loading state
    commit('CLEAR_ERROR', null, { root: true })
    commit('TOGGLE_LOADING', null, { root: true })
    try {
      const accountClient = await tozny.loginWithPaperKey(
        accountService,
        account
      )
      // Persist account client object for future account api calls to session and app store
      await dispatch('loadAccountClientToSession', accountClient)
      commit('SET_ACCOUNT_CLIENT', { accountClient })
      // Clear loading state
      commit('TOGGLE_LOADING', null, { root: true })
      return true
    } catch (error) {
      // Show the user the returned error
      commit('SET_ERROR', 'Username or paper key incorrect.', { root: true })
      // Clear loading state
      commit('TOGGLE_LOADING', null, { root: true })
      return false
    }
  },
  async initiateRecoverWithEmail(_, email) {
    await tozny.initiateRecoverWithEmail(accountService, email)
    return true
  },
  /** loadAccountClientToSession persists the provided account client to session storage */
  async loadAccountClientToSession(_, accountClient) {
    sessionStorage.setItem(
      'account_client',
      JSON.stringify(accountClient.serialize())
    )
  },
  /** loadAccountFromSession loads the user's account based on session storage */
  async loadAccountFromSession({ commit }) {
    const accountSession = JSON.parse(sessionStorage.getItem('account_client'))
    if (!accountSession) {
      // Don't try to load client from null config, errors will occur
      return false
    }
    const accountClient = accountService.fromObject(accountSession)
    await accountClient.refreshProfile()
    commit('SET_ACCOUNT_CLIENT', { accountClient })
  },
  /**
   * authenticateUser attempts to authenticate the current user based on a serial combination
   * of store state and the session storage.
   * authenticateUser returns true if they have a stored account object for making account service requests
   * If the store state is null, authenticateUser calls `loadAccountFromSession`,
   * which if the user has a valid session will result in non null states for those stored objects.
   * Under these conditions, the flow for initial login, App refresh, and direct link navigation is:
   * Login
   *   (app state will be empty)
   *   store serialized account client object string for rehydration
   *     account
   *   store account client object in state for cached access
   *     account
   *   on route change
   *     check for stored state objects
   *     if present
   *       return true
   *     else
   *       return false (router.js will redirect user to login page)
   * App Initialization / Page Refresh / Direct URL navigation
   *   (app state will be cleared out)
   *   check for stored serialized credentials `dispatch('account/loadAccountFromSession')`
   *     if present
   *       rehydrate
   *     else
   *       return false ((router.js will redirect user to login page if route requires authentication)
   */
  async authenticateUser({ dispatch, state }) {
    // !! checks both undefined & null
    if (!state.accountClient) {
      // attempt to rehydrate account and queen clients from session storage
      await dispatch('loadAccountFromSession')
    }
    return !!state.accountClient
  },
  /**
   * adds user profile info to intercom session
   */
  async updateIntercom({ state }) {
    const name = state.accountClient.profile.name
    const email = state.accountClient.profile.email
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: global.INTERCOM_WORKSPACE_ID,
        name,
        email,
      })
    }
  },
  /**
   * logout logs the user out
   * If logout is successful
   *     the client credentials and account metadata for the account will be removed from local storage
   *     the window location will be changed to login
   *     page will be reloaded and clear the Vuex stores
   */
  // TODO:  Try to get the errors out of the console throw by getters in Dashboard components before re-route.
  async logout() {
    sessionStorage.clear()
    /*
        Changing the window.location will cause a page reload.
        The page reload will clear the Vuex store (with all modules).
        The app will then have no accountClient info, and the router will grant access to /login.
    */
    window.location = `${window.location.origin}/login`
  },
  /*
    editProfile handles a request to change a user's name and email (aka username).
    editProfile calls the tozny api method with the accountClient, updates the
    accountClient's account and profile in state, updates the sessionStorage
    representation of the accountClient from the accountClient in state (this includes
    the username in the refresher).
  */
  async editProfile({ commit, dispatch, rootState }, { name, email }) {
    commit('CLEAR_CHANGE_PROFILE_ERROR')
    commit('TOGGLE_CHANGE_PROFILE_LOADING')
    const accountClient = rootState.account.accountClient
    try {
      const newProfile = await tozny.editProfile(accountClient, { name, email })
      const { account, profile } = newProfile
      commit('SET_NEW_ACCOUNT_AND_PROFILE', { account, profile })
      await dispatch('loadAccountClientToSession', accountClient)
      commit('SET_CHANGE_PROFILE_SUCCESS_MESSAGE')
      setTimeout(() => {
        commit('CLEAR_CHANGE_PROFILE_SUCCESS_MESSAGE')
      }, 5000)
      commit('TOGGLE_CHANGE_PROFILE_LOADING')
      return true
    } catch (error) {
      // Show the user the returned error
      commit('SET_CHANGE_PROFILE_ERROR', 'Could not update profile.')
      // Clear loading state
      commit('TOGGLE_CHANGE_PROFILE_LOADING')
      return false
    }
  },
  /* */
  async changeAccountPassword(
    { commit, rootState, dispatch },
    { password, newPassword, type }
  ) {
    commit('CLEAR_CHANGE_PASSWORD_ERROR')
    commit('TOGGLE_CHANGE_PASSWORD_LOADING')
    const accountClient = rootState.account.accountClient
    try {
      const newProfile = await tozny.changePassword(accountClient, {
        password,
        newPassword,
        type,
      })
      const { account, profile } = newProfile
      commit('SET_NEW_ACCOUNT_AND_PROFILE', { account, profile })
      await dispatch('loadAccountClientToSession', accountClient)
      commit('SET_CHANGE_PASSWORD_SUCCESS')
      setTimeout(() => {
        commit('CLEAR_CHANGE_PASSWORD_SUCCESS')
      }, 5000)
      commit('TOGGLE_CHANGE_PASSWORD_LOADING')
      /* Presents the user with a success message, then automatically logs out.
         The account-sdk does not support the user in continuing with a paperkey
         logged in session.
         The paperkey auth should only be used to recover and reset password,
         then the user should be directed to log in again with their password.
      */
      if (type === 'paper') {
        setTimeout(async () => {
          await dispatch('logout', { root: true })
        }, 3000)
      }
      /*
        A setTimeout will remove the success message that displays.
        This helps prevent bugs from success message state values
        used in multiple components persisting / displaying unexpectedly.
      */
      setTimeout(() => {
        commit('PAPERKEY_SUCCESS_FALSE')
      }, 5000)
      return true
    } catch (err) {
      const errorMsg =
        `Could not change password. Error:  ${err.message}` ||
        'Could not change password.'
      commit('SET_CHANGE_PASSWORD_ERROR', errorMsg)
      commit('TOGGLE_CHANGE_PASSWORD_LOADING')
      return false
    }
  },

  async updateAccountBilling({ commit, dispatch, rootState }, stripeToken) {
    commit('CLEAR_UPDATE_BILLING_ERROR')
    commit('TOGGLE_UPDATE_BILLING_LOADING')
    const accountClient = rootState.account.accountClient
    try {
      const updateBillingResponse = await tozny.updateAccountBilling(
        accountClient,
        stripeToken
      )
      const account = updateBillingResponse.account
      const profile = updateBillingResponse.profile
      commit('SET_NEW_ACCOUNT_AND_PROFILE', { account, profile })
      commit('TOGGLE_UPDATE_BILLING_LOADING')
      /*
        This action is currently being called from Billing.
        TODO: Figure out why examples of calling action from one module
        in another do not work here.
        await dispatch('checkValidBilling', null, { root: true })
       */
      await dispatch('loadAccountClientToSession', accountClient)
      return true
    } catch (err) {
      const errorMsg =
        `Unable to update card. Error:  ${err.message}` ||
        'Unable to update card.'
      commit('SET_UPDATE_BILLING_ERROR', errorMsg)
      commit('TOGGLE_UPDATE_BILLING_LOADING')
      return false
    }
  },

  /** verifyEmail verifies the account email associated with a Tozny account.
   *
   *  Displays error to user if
   *     request fails
   *     account service is down
   * @param {string} toznyo - a one-time code generated by tozauth
   * @param {string} toznyr - a realm name generated by tozauth
   *
   * @returns response
   */
  async verifyEmail({ commit, dispatch }, { id, otp }) {
    commit('TOGGLE_LOADING', null, { root: true })
    dispatch('clearErrorMessage', null, { root: true })
    try {
      await tozny.verifyEmail(accountService, id, otp)
      await dispatch('loadAccountFromSession')
      commit('TOGGLE_LOADING', null, { root: true })
      return true
    } catch (err) {
      dispatch('setValidationError', 'We were unable to verify your email.', {
        root: true,
      })
      commit('TOGGLE_LOADING', null, { root: true })
      return false
    }
  },

  /** authenticateEmail verifies the account email associated with a Tozny account.
   *
   *  Displays error to user if
   *     request fails
   *     account service is down
   * @param {string} toznyo - a one-time code generated by tozauth
   * @param {string} toznyr - a realm name generated by tozauth
   *
   * @returns response
   */
  async authenticateEmail({ commit, dispatch }, { id, otp }) {
    commit('TOGGLE_LOADING', null, { root: true })
    dispatch('clearErrorMessage', null, { root: true })
    try {
      const profile = await tozny.authenticateEmail(accountService, id, otp)
      await dispatch('loadAccountFromSession')
      commit('TOGGLE_LOADING', null, { root: true })
      return profile
    } catch (err) {
      dispatch('setValidationError', 'We were unable to verify your email.', {
        root: true,
      })
      commit('TOGGLE_LOADING', null, { root: true })
      return false
    }
  },

  async recoverAccount({ dispatch, commit }, { newPassword, accountToken }) {
    try {
      const recoverResp = await tozny.recoverAccount(
        accountService,
        newPassword,
        accountToken
      )
      await dispatch('loadAccountClientToSession', recoverResp.newQueenClient)
      commit('SET_ACCOUNT_CLIENT', {
        accountClient: recoverResp.newQueenClient,
      })
      return recoverResp.paperKey
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('this was error changing password', err)
      dispatch('setValidationError', 'We were unable to change your password', {
        root: true,
      })
      commit('TOGGLE_LOADING', null, { root: true })
      return false
    }
  },

  /** resendVerificationEmail requests the Tozny account verification email be resent
   */
  async resendVerificationEmail({ rootState }) {
    const accountClient = rootState.account.accountClient
    try {
      await tozny.resendVerificationEmail(accountClient)
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  },
}

export default {
  // namespace this modules actions, mutations, and getters under '/account' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
