import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import { REGISTRATION_DISABLED } from '../utils/utils'
import { STRIPE_ENABLED } from '../utils/utils'

Vue.use(Router)

const uuidRegEx =
  '[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => {
      return import(
        /* webpackChunkName: "dashboard" */ '../Pages/Dashboard/index.vue'
      )
    },
    meta: {
      requiresLogin: true,
      requiresBilling: true,
      menu: 'dashboard',
      title: 'Tozny - Dashboard',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => {
      return import(/* webpackChunkName: "login" */ '../Pages/Login/index.vue')
    },
    meta: {
      requiresNotLoggedIn: true,
      title: 'Tozny - Login',
    },
  },
  {
    path: '/register',
    name: 'register',
    beforeEnter(to, from, next) {
      // Only route to this page is registration is not disabled.
      if (REGISTRATION_DISABLED) {
        next({ name: 'login' })
        return
      }
      next()
    },
    meta: {
      requiresNotLoggedIn: true,
      title: 'Tozny - Register',
    },
    component: () => {
      return import(
        /* webpackChunkName: "register" */ '../Pages/Register/index.vue'
      )
    },
  },
  {
    path: '/confirm',
    name: 'verification',
    meta: {
      title: 'Tozny - Verify Your Account',
    },
    component: () => {
      return import(
        /* webpackChunkName: "verifcation" */ '../Pages/Verification/index.vue'
      )
    },
  },
  {
    path: '/recover',
    name: 'recover',
    meta: {
      requiresNotLoggedIn: true,
      title: 'Tozny - Recover',
    },
    component: () => {
      return import(
        /* webpackChunkName: "recover" */ '../Pages/RecoverPassword/index.vue'
      )
    },
  },
  {
    path: '/recover-mfa',
    name: 'recover-mfa',
    meta: {
      requiresNotLoggedIn: true,
      title: 'Tozny - Recover MFA',
    },
    component: () => {
      return import(
        /* webpackChunkName: "recoverMFA" */ '../Pages/RecoverMFA/index.vue'
      )
    },
  },
  {
    path: '/tokens',
    name: 'tokens',
    // route level code-splitting
    // this generates a separate chunk (tokens.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => {
      return import(
        /* webpackChunkName: "tokens" */ '../Pages/Tokens/index.vue'
      )
    },
    meta: {
      requiresLogin: true,
      requiresBilling: true,
      menu: 'tokens',
      menuGroup: 'tozstore',
      title: 'TozStore - Tokens',
    },
  },
  {
    // when /clients/:client_id is matched
    path: `/clients/:clientId(${uuidRegEx})`,
    name: 'singleClient',
    component: () => {
      return import(
        /* webpackChunkName: "singleClient" */ '../Pages/Client/index.vue'
      )
    },
    meta: {
      requiresLogin: true,
      requiresBilling: true,
      menu: 'clients',
      menuGroup: 'tozstore',
      title: 'TozStore - Clients',
    },
    children: [
      {
        // when /clients/:client_id/record/:record_id is matched
        path: `record/:recordId(${uuidRegEx})`,
        name: 'singleRecord',
        component: () => {
          return import(
            /* webpackChunkName: "singleRecord" */ '../Pages/Client/Record/index.vue'
          )
        },
        children: [
          {
            // when /clients/:client_id/record/:record_id is matched
            path: '',
            name: 'viewRecord',
            component: () => {
              return import(
                /* webpackChunkName: "singleRecord" */ '../Pages/Client/Record/ViewRecord.vue'
              )
            },
          },
          {
            // when /clients/:client_id/record/:record_id is matched
            path: 'edit',
            name: 'editRecord',
            component: () => {
              return import(
                /* webpackChunkName: "singleRecord" */ '../Pages/Client/Record/EditRecord.vue'
              )
            },
          },
        ],
      },
      {
        // when /clients/:client_id/record/new is matched
        path: 'record/new',
        name: 'addRecord',
        component: () => {
          return import(
            /* webpackChunkName: "addRecord" */ '../Pages/Client/Record/AddRecord.vue'
          )
        },
      },
    ],
  },
  {
    path: '/clients',
    name: 'clients',
    // route level code-splitting
    // this generates a separate chunk (clients.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => {
      return import(
        /* webpackChunkName: "clients" */ '../Pages/Clients/index.vue'
      )
    },
    meta: {
      requiresLogin: true,
      requiresBilling: true,
      menu: 'clients',
      menuGroup: 'tozstore',
      title: 'TozStore - Clients',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    // route level code-splitting
    // this generates a separate chunk (profile.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => {
      return import(
        /* webpackChunkName: "profile" */ '../Pages/Profile/index.vue'
      )
    },
    meta: {
      requiresLogin: true,
      requiresBilling: true,
      menu: 'profile',
      menuGroup: 'settings',
      title: 'Tozny - Your Profile',
    },
  },
  {
    path: '/advanced',
    name: 'advanced',
    // route level code-splitting
    // this generates a separate chunk (advanced.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => {
      return import(
        /* webpackChunkName: "advanced" */ '../Pages/Advanced/index.vue'
      )
    },
    meta: {
      requiresLogin: true,
      requiresBilling: true,
      menu: 'advanced',
      menuGroup: 'tozstore',
      title: 'TozStore - Advanced',
    },
  },
  {
    path: '/identity',
    component: () => {
      return import(
        /* webpackChunkName: "advanced" */ '../Pages/Identity/Realms/index.vue'
      )
    },
    meta: {
      requiresLogin: true,
      requiresBilling: true,
      menuGroup: 'tozid',
      title: 'TozId',
    },
    children: [
      {
        path: 'realms',
        component: () => {
          return import(
            /* webpackChunkName: "advanced" */ '../Pages/Identity/Realms/index.vue'
          )
        },
        meta: {
          title: 'TozId - Realms',
          menu: 'realms',
        },
      },
    ],
  },
  {
    path: `/groups/:groupId(${uuidRegEx})`,
    name: 'singleGroup',
    component: () => {
      return import(
        /* webpackChunkName: "singleGroup" */ '../Pages/Group/index.vue'
      )
    },
    meta: {
      requiresLogin: true,
      requiresBilling: true,
      menu: 'groups',
      menuGroup: 'tozstore',
      title: 'TozStore - Groups',
    },
  },
  {
    path: '/groups',
    component: () => {
      return import(/*webpackChunkName: "groups" */ '../Pages/Groups/index.vue')
    },
    meta: {
      title: 'TozStore - Groups',
      menu: 'groups',
    },
  },
  {
    path: `/groups/:groupId(${uuidRegEx})/members`,
    name: 'addGroupMembers',
    component: () => {
      return import(
        /* webpackChunkName: "addGroupMembers" */ '../Pages/Group/GroupMember/AddGroupMember.vue'
      )
    },
    meta: {
      menu: 'groups',
      menuGroup: 'tozstore',
      title: 'TozStore - Groups',
    },
  },
  {
    // will match everything else
    path: '*',
    name: '404',
    component: () => {
      return import(/* webpackChunkName: "404" */ '../Pages/404')
    },
    meta: {
      requiresLogin: true,
      requiresBilling: true,
      title: 'Tozny - 404',
    },
  },
]

const subscriptionRoute = {
  path: '/subscription',
  name: 'subscription',
  // route level code-splitting
  // this generates a separate chunk (subscription.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => {
    return import(
      /* webpackChunkName: "subscription" */ '../Pages/Subscription/index.vue'
    )
  },
  meta: {
    requiresLogin: true,
    menu: 'subscription',
    menuGroup: 'settings',
    title: 'Tozny - Subscription',
  },
}

if (STRIPE_ENABLED) {
  routes.push(subscriptionRoute)
}

const router = new Router({
  routes: routes,
  mode: 'history',
})

/**
 * Route guards that run before each route change to restrict access to routes based
 * upon desired criteria (e.g. authenticated, account not expired/blacklisted)
 *
 */
router.beforeEach(async (to, from, next) => {
  // Enforce authentication on routes that require it
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  const userIsAuthenticated = await store.dispatch('account/authenticateUser')
  let validBilling
  if (userIsAuthenticated) {
    validBilling = await store.dispatch('billing/checkValidBilling')
  }
  const routeRequiresAuthentication = to.matched.some(
    (route) => route.meta.requiresLogin
  )
  if (routeRequiresAuthentication && !userIsAuthenticated) {
    next({ name: 'login' })
    return
  }
  // Enforce active billing status on routes that require payment
  const routeRequiresBilling = to.matched.some(
    (route) => route.meta.requiresBilling
  )
  if (routeRequiresBilling) {
    if (!validBilling) {
      next({ name: 'subscription' })
      return
    }
  }
  // Enforce only accessible to user's before they log in or after log out
  const routeRequiresNotLoggedIn = to.matched.some(
    (route) => route.meta.requiresNotLoggedIn
  )
  if (routeRequiresNotLoggedIn && userIsAuthenticated) {
    next(from)
    return
  }
  // If all route checks pass, allow the user to navigate to the desired route
  next()
})

export default router
