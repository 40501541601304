import Tozny from '@toznysecure/sdk/browser'

/**
 * State associated with realm management.
 */

let ToznyLambdaBrokerClientId
const ToznyDefaultAPIUrl = window.API_URL
const TozIDUIURL = window.TOZID_UI_URL

/** initial realm state */
const state = {
  status: 'initializing',
  realms: [],
  errorMessage: '',
  realmToDelete: {},
  selectedRealmId: null,
  createdRealm: {},
}

/** synchronous mutations of realm state */
const mutations = {
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_REALMS(state, { realms }) {
    state.realms = realms
  },
  SET_ERROR(state, { error, status }) {
    state.errorMessage = error
    state.status = status
  },
  CLEAR_ERROR(state) {
    state.errorMessage = ''
  },
  SET_CREATED_REALM(state, realm) {
    state.createdRealm = realm
  },
  CLEAR_CREATED_REALM(state) {
    state.createdRealm = {}
  },
  SET_REALM_TO_DELETE(state, realm) {
    state.realmToDelete = realm
  },
  CLEAR_REALM_TO_DELETE(state) {
    state.realmToDelete = {}
  },
  SET_SELECTED_REALM(state, realmId) {
    state.selectedRealmId = realmId
  },
}

const statuses = [
  'initializing',
  'loading',
  'zero',
  'zero.adding',
  'idle',
  'add',
  'adding',
  'globalError',
  'realm.delete',
  'realm.detail',
]

/**
 * Callable state transitions that facilitate async actions and state transitions
 */
const actions = {
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', 'Error: Unknown State', 'globalError')
    }
  },
  /**
   * Syncs the state machine with the application state, fetching as necessary.
   * @param {context} param0 The vuex state context
   */
  async initialize({ commit, dispatch, rootState }) {
    //always reload
    await dispatch('transitionStatus', 'initializing')
    try {
      // Set the hosted broker client ID if needed
      if (!ToznyLambdaBrokerClientId) {
        const accountClient = rootState.account.accountClient
        window.testClient = accountClient
        const info = await accountClient.hostedBrokerInfo()
        ToznyLambdaBrokerClientId = info.client_id
      }
      // Load up the realms
      await dispatch('loadRealms')
    } catch (e) {
      // handle errors
      const error = e.message
      commit('SET_ERROR', { error, status: 'idle' })
    }
  },

  async closeAddRealmDialogue({ commit }) {
    commit('CLEAR_CREATED_REALM')
  },

  async loadRealms({ commit, dispatch, rootState, rootGetters }) {
    // set loading state
    await dispatch('transitionStatus', 'loading')
    // obtain account client
    let accountClient = rootState.account.accountClient
    let queenClient = rootGetters['account/queenClient']
    try {
      // make call to list realms
      let realmResp = await accountClient.listRealms()
      let realms = realmResp.realms.map((r) => {
        r.getLoginUrl = async () => {
          // Get User Count
          let userCount = await accountClient.getRealmUserCount(r.domain)
          r.count = userCount.identity_count
          const queenId = queenIdClient(accountClient, r)
          const token = await queenId.token()
          const challengeBytes = new Uint8Array(32)
          window.crypto.getRandomValues(challengeBytes)
          const challenge = Array.prototype.map
            .call(challengeBytes, (x) => ('00' + x.toString(16)).slice(-2))
            .join('')
          return (
            `${TozIDUIURL}/auth/realms/${r.domain}/protocol/openid-connect/auth?` +
            'client_id=security-admin-console&response_type=code&scope=openid' +
            `&redirect_uri=${encodeURIComponent(r.adminURL)}` +
            `&jwt=${encodeURIComponent(token)}` +
            `&code_challenge_method=S256&code_challenge=${challenge}`
          )
        }
        return r
      })
      await areRealmsBrokered(queenClient, realms)
      // set realms to page state
      commit('SET_REALMS', { realms })

      await dispatch('loadUserCountForAllRealms')
      // decide on zero page state or show list of realms
      const realmHome = realms.length ? 'idle' : 'zero'
      await dispatch('transitionStatus', realmHome)
    } catch (e) {
      // handle errors
      const error = e.message
      commit('SET_ERROR', { error, status: 'idle' })
    }
  },

  async loadUserCountForAllRealms({ commit, rootState }) {
    const realms = [...rootState.realms.realms]
    const accountClient = rootState.account.accountClient
    try {
      await Promise.all(
        realms.map(async (realm) => {
          let userCount = await accountClient.getRealmUserCount(realm.domain)
          realm.count = userCount.identity_count
          return realm
        })
      )
      commit('SET_REALMS', { realms })
    } catch (e) {
      const error = e.message
      commit('SET_ERROR', { error, status: 'idle' })
    }
  },

  async addRealm({ commit, dispatch, rootState, rootGetters }, realmName) {
    await dispatch('transitionStatus', 'loading')
    const accountClient = rootState.account.accountClient
    let queenClient = rootGetters['account/queenClient']
    const email = accountClient.profile.email
    try {
      let realmTokenName = `${realmName}DefaultToken`
      const registrationTokenResp = await accountClient.newRegistrationToken(
        realmTokenName,
        { enabled: true, allowed_types: ['general', 'identity', 'broker'] }
      )
      const realmRegistrationToken = registrationTokenResp.token
      const createResp = await accountClient.createRealm(
        realmName,
        email,
        realmRegistrationToken
      )
      // register realm broker identity
      const brokerIdentity = await registerRealmBrokerIdentity(
        accountClient,
        realmName,
        realmRegistrationToken
      )
      // write realm broker to note and encode access and key material into a easily shareable token
      const realmBrokerToken = await writeRealmBrokerNote(
        queenClient,
        brokerIdentity
      )
      // save realm broker token for securely fetching and displaying in the dashboard
      await saveRealmBrokerToken(queenClient, realmName, realmBrokerToken)
      // show created realm info
      commit('SET_CREATED_REALM', createResp)

      // turn on email recovery by default
      // toggle switch flips this flag before it's sent to this method for processing,
      // so this must be true, even though it is not currently true.
      createResp.isBrokerAuthorized = true
      try {
        await dispatch('internalSetEmailRecovery', createResp)
      } catch (e) {
        var error = e.message
        if (e.response) {
          if (e.response.status == 404) {
            error =
              // eslint-disable-next-line
              `Your realm was created successfully, but email recovery couldn't be enabled at this time.`
            await dispatch('loadRealms')
            commit('SET_ERROR', { error, status: 'idle' })
            return
          }
        }
        throw e
      }
      // Reload realms from SOT (identity-service)
      await dispatch('loadRealms')
    } catch (e) {
      error = e.message
      if (e.response) {
        if (e.response.status == 409) {
          error = 'Sorry a realm with that name already exists'
        }
      }
      // set error message and go back to adding page
      commit('SET_ERROR', { error, status: 'add' })
    }
  },

  async deleteRealm({ commit, dispatch, rootState }, realm) {
    await dispatch('transitionStatus', 'loading')
    let accountClient = rootState.account.accountClient
    try {
      await accountClient.deleteRealm(realm.domain)
      dispatch('disableEmailRecoveryByRealmName', realm.name) // Don't await as best effort and no-op if not shared
      // Reload realms from SOT (identity-service)
      await dispatch('loadRealms')
    } catch (e) {
      const error = e.message
      commit('SET_ERROR', { error, status: 'realm.detail' })
    }
  },

  async disableEmailRecoveryByRealmName({ rootGetters }, realmName) {
    let queenClient = rootGetters['account/queenClient']
    const recordType = `${realmName}.backup.token`
    await queenClient.removeAuthorizer(recordType, ToznyLambdaBrokerClientId)
    const realmBrokerBackupTokenQuery = await queenClient
      .query(false, null, null, recordType)
      .next()
    for (const index in realmBrokerBackupTokenQuery) {
      const record = realmBrokerBackupTokenQuery[index]
      await queenClient.deleteRecord(record.meta.recordId, record.meta.version)
    }
  },

  async internalSetEmailRecovery({ rootGetters }, realm) {
    let queenClient = rootGetters['account/queenClient']
    const recordType = `${realm.name}.backup.token`
    const operation = realm.isBrokerAuthorized
      ? 'addAuthorizer'
      : 'removeAuthorizer'
    await queenClient[operation](recordType, ToznyLambdaBrokerClientId)
  },

  async setEmailRecovery({ commit, dispatch }, realm) {
    try {
      await dispatch('internalSetEmailRecovery', realm)
    } catch (e) {
      var error = e.message
      if (e.response) {
        if (e.response.status == 404) {
          // eslint-disable-next-line
          error = `Sorry email recovery can't be enabled at this time`
        }
      }
      commit('SET_ERROR', { error: error, status: 'realm.detail' })
      realm.isBrokerAuthorized = false
    }
  },

  async confirmDelete({ commit, dispatch }, realm) {
    // save realm we want to delete to state
    commit('SET_REALM_TO_DELETE', realm)
    // show confirm modal
    await dispatch('transitionStatus', 'realm.delete')
  },

  async cancelDelete({ commit, dispatch }) {
    // clear realm from deletion state
    commit('CLEAR_REALM_TO_DELETE')
    // show realm detail view
    await dispatch('transitionStatus', 'realm.detail')
  },

  async selectRealm({ commit, dispatch }, realmId) {
    commit('SET_SELECTED_REALM', realmId)
    await dispatch('transitionStatus', 'realm.detail')
  },

  async dismissGlobalError({ commit }) {
    commit('CLEAR_ERROR')
  },
}

async function writeRealmBrokerNote(queenClient, brokerIdentity) {
  // generate realm broker token seed to use for deriving broker note key material
  const notePassword = await queenClient.crypto.platform.b64URLEncode(
    await queenClient.crypto.randomKey()
  )
  const encNonce = await queenClient.crypto.randomNonce()
  const signNonce = await queenClient.crypto.randomNonce()
  const tokenSeed =
    notePassword +
    (await queenClient.crypto.platform.b64URLEncode(encNonce)) +
    (await queenClient.crypto.platform.b64URLEncode(signNonce))
  // derive encryption and signing keypairs based off the token seed
  const keypair = await queenClient.crypto.deriveCryptoKey(
    notePassword,
    encNonce,
    10000
  )
  const signingKeypair = await queenClient.crypto.deriveSigningKey(
    notePassword,
    signNonce,
    10000
  )
  // write never expiring note of realm broker identity credentials
  const noteBody = {
    realmId: String(brokerIdentity.realmId),
    realmName: brokerIdentity.realmName,
    client: JSON.stringify(toznyClientConfigFromIdentity(brokerIdentity)),
    publicKey: signingKeypair.publicKey,
  }
  const note = await queenClient.writeNote(
    noteBody,
    keypair.publicKey,
    signingKeypair.publicKey,
    {
      max_views: -1,
      expires: false,
    }
  )
  // synthesize token to be used to derive keys for fetching and decrypting this broker note
  const brokerNoteToken = tokenSeed + note.noteId
  return brokerNoteToken
}

async function saveRealmBrokerToken(
  accountClient,
  realmName,
  realmBrokerToken
) {
  const recordType = `${realmName}.backup.token`
  let resp = await accountClient.writeRecord(
    recordType,
    { token: realmBrokerToken },
    {}
  )
  return resp
}

async function registerRealmBrokerIdentity(
  accountClient,
  realmName,
  realmRegistrationToken
) {
  // Create a registration token to use when registering the broker identity
  return accountClient.registerRealmBrokerIdentity(
    realmName,
    realmRegistrationToken
  )
}

// check the broker status of each realm
async function areRealmsBrokered(queenClient, realms) {
  try {
    let authorizers = await queenClient.getAuthorizers()
    for (let i = 0; i < realms.length; i++) {
      let brokerRecordType = `${realms[i].name}.backup.token`
      // !! operator shorthand to convert value to bool
      const isBrokerAuthorized = !!authorizers.find(
        (s) =>
          s.recordType === brokerRecordType &&
          s.authorizerId === ToznyLambdaBrokerClientId
      )
      realms[i].isBrokerAuthorized = isBrokerAuthorized
    }
  } catch (error) {
    // This error is not worth holding up user action, but log the error for transparency
    //eslint-disable-next-line
    console.log('Error fetching broker for realms', realms, error)
  }
}

function toznyClientConfigFromIdentity(brokerIdentity) {
  const clientConfig = {
    api_key_id: brokerIdentity.apiKeyId,
    api_secret: brokerIdentity.apiSecretKey,
    api_url: ToznyDefaultAPIUrl,
    client_email: '',
    client_id: brokerIdentity.toznyId,
    private_key: brokerIdentity.privateKey.curve25519,
    private_signing_key: brokerIdentity.privateSigningKey.ed25519,
    public_key: brokerIdentity.publicKey.curve25519,
    public_signing_key: brokerIdentity.signingKey.ed25519,
    name: brokerIdentity.name,
    version: 2,
  }
  return clientConfig
}

function queenIdClient(accountClient, realm) {
  const realmConfig = Tozny.identity.Config.fromObject({
    realmName: realm.domain,
    appName: 'admin-cli',
    username: accountClient.profile.email,
    apiUrl: accountClient.queenClient.config.apiUrl,
  })
  return new Tozny.identity.Client(realmConfig, accountClient.queenClient, {})
}

const getters = {
  selectedRealm: (state) => {
    const filteredRealms = state.realms
      .slice()
      .filter((h) => h.id === state.selectedRealmId)
    return filteredRealms.length ? filteredRealms[0] : false
  },
  totalUsersAcrossAllRealms: (state) => {
    return state.realms.reduce((total, realm) => {
      return total + (realm.count ? realm.count : 0)
    }, 0)
  },
}

export default {
  // namespace this modules actions, mutations, and getters under '/identity/realms' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
