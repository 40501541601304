/**
 * State associated with registration tokens.
 */

/**
 * state and actions surrounding Tozny registration tokens
 * @module tokens
 */
import tozny from '../../api/tozny'

/** initial token state */
const state = {
  /** the account's current billing status */
  status: 'initializing',
  tokens: [],
  errorMessage: '',
  deleteQueue: {},
}

/** cache-able getters for the current users Tozny tokens resources */
const getters = {
  accountHasTokens: (state) => {
    return !!state.tokens.length
  },
}

/** synchronous mutations of tokens state */
const mutations = {
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_TOKENS(state, { tokens }) {
    state.tokens = tokens
  },
  SET_ERROR(state, { error, status }) {
    state.errorMessage = error
    state.status = status
  },
  CLEAR_ERROR(state) {
    state.errorMessage = ''
  },
  SET_DELETE_QUEUE(state, { token }) {
    state.deleteQueue = token
  },
  CLEAR_DELETE_QUEUE() {
    state.deleteQueue = {}
  },
}

const statuses = [
  'initializing',
  'loading',
  'zero',
  'zero.adding',
  'idle',
  'idle.delete',
  'add',
  'adding',
  'globalError',
]

/**
 * Callable state transitions that facilitate async actions and state transitions
 */
const actions = {
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', 'Error: Unknown State', 'globalError')
    }
  },
  /**
   * Syncs the state machine with the application state, fetching as necessary.
   * @param {context} param0 The vuex state context
   */
  async initialize({ dispatch, state }) {
    // Only act on initializing state
    if (state.status !== 'initializing') {
      return
    }
    // Determine if initial fetch is necessary
    if (!state.tokens.length) {
      await dispatch('transitionStatus', 'loading')
      await dispatch('loadTokens')
    } else {
      await dispatch('transitionStatus', 'idle')
    }
  },
  async loadTokens({ commit, dispatch, rootState }) {
    try {
      const accountClient = rootState.account.accountClient
      const tokens = await tozny.registrationTokens(accountClient)
      commit('SET_TOKENS', { tokens })
      const transition = tokens.length ? 'idle' : 'zero'
      await dispatch('transitionStatus', transition)
    } catch (e) {
      const error = e.message
      commit('SET_ERROR', { error, status: 'globalError' })
    }
  },
  async createToken({ commit, dispatch, state, rootState }, token) {
    try {
      const accountClient = rootState.account.accountClient
      const writtenToken = await tozny.writeRegistrationToken(
        accountClient,
        token.name,
        token.permissions
      )
      const tokens = [...state.tokens, writtenToken]
      commit('SET_TOKENS', { tokens })
      dispatch('transitionStatus', 'idle')
    } catch (e) {
      const status = state.status === 'zero.adding' ? 'zero' : 'idle'
      const error = e.message
      commit('SET_ERROR', { error, status })
    }
  },
  async deleteToken({ commit, dispatch, state, rootState }, token) {
    const original = state.tokens.slice(0)
    const modified = state.tokens
      .slice(0)
      .filter((t) => t.token !== token.token)
    commit('SET_TOKENS', { tokens: modified })
    const transition = modified.length > 0 ? 'idle' : 'zero'
    await dispatch('transitionStatus', transition)
    try {
      const accountClient = rootState.account.accountClient
      await tozny.deleteRegistrationToken(accountClient, token)
    } catch (e) {
      commit('SET_TOKENS', { tokens: original })
      const error = e.message
      commit('SET_ERROR', { error, status: 'idle' })
    }
  },
  async enqueueDelete({ commit, dispatch }, token) {
    commit('SET_DELETE_QUEUE', { token })
    await dispatch('transitionStatus', 'idle.delete')
  },
  async cancelDeleteQueue({ commit, dispatch }) {
    commit('CLEAR_DELETE_QUEUE')
    await dispatch('transitionStatus', 'idle')
  },
}

export default {
  // namespace this modules actions, mutations, and getters under '/tokens' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
