import Vue from 'vue'
import Vuex from 'vuex'

import router from '../router/router.js'
import account from './modules/account'
import billing from './modules/billing'
import clients from './modules/clients'
import metrics from './modules/metrics'
import mfa from './modules/mfa'
import nav from './modules/nav'
import tokens from './modules/tokens'
import webhooks from './modules/webhooks'
import realms from './modules/realms'
import groups from './modules/groups'
import group from './modules/group'
import { REGISTRATION_DISABLED } from '../utils/utils'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    account,
    billing,
    clients,
    metrics,
    mfa,
    nav,
    tokens,
    webhooks,
    realms,
    groups,
    group,
  },
  state: {
    displayError: '',
    loading: false,
    registrationDisabled: REGISTRATION_DISABLED,
  },
  getters: {},
  mutations: {
    SET_ERROR(state, displayError) {
      state.displayError = displayError
    },
    CLEAR_ERROR(state) {
      state.displayError = ''
    },
    TOGGLE_LOADING(state) {
      state.loading = !state.loading
    },
  },
  actions: {
    async pushRoute({ commit }, pathOrOptions) {
      commit('CLEAR_ERROR')
      router.push(pathOrOptions)
    },
    async replaceRoute({ commit }, pathOrOptions) {
      commit('CLEAR_ERROR')
      router.replace(pathOrOptions)
    },
    async setValidationError({ commit }, errorString) {
      commit('SET_ERROR', errorString)
    },
    async clearErrorMessage({ commit }) {
      commit('CLEAR_ERROR')
    },
  },
})

export default store
