/**
 * client implements user level actions of Tozny client list level resources
 * @module clients
 */
import client from './client'
import tozny from '../../api/tozny'

const statuses = [
  'initializing',
  'loading',
  'zero',
  'zero.adding',
  'idle',
  'add',
  'add.adding',
  'error',
]

/** submodules */
const modules = {
  client,
}

/** initial client state */
const state = {
  status: 'initializing',
  clientIds: [],
  clientObjects: {},
  errorMessage: '',
  hasMore: true,
  initialized: false,
  nextToken: undefined,
}

/** cache-able getters for the current users Tozny client list level resources */
const getters = {
  clients(state) {
    // Turn all the stored client IDs into full client objects
    return state.clientIds.map((id) => state.clientObjects[id])
  },
  getClient(state) {
    return (id) => state.clientObjects[id]
  },
  disabled(state, getters, rootState) {
    return rootState.tokens.tokens.length === 0
  },
  hasMore(state) {
    return state.nextToken !== 0
  },
}

/** synchronous mutations of client state */
const mutations = {
  INITIALIZED(state) {
    state.initialized = true
  },
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_ERROR(state, { message, status }) {
    state.errorMessage = message
    state.status = status
  },
  SET_CLIENTS(state, { idList, objects }) {
    state.clientIds = idList
    state.clientObjects = objects
  },
  ADD_CLIENT(state, client) {
    // create a shallow clone so when assigned it is recognized as an actual update.
    // Otherwise the object is just mutated in place
    const objects = Object.assign({}, state.clientObjects)
    objects[client.clientId] = client
    const idList = [...state.clientIds, client.clientId]
    state.clientObjects = objects
    state.clientIds = idList
  },
  REMOVE_CLIENT(state, clientId) {
    // create a shallow clone so when assigned it is recognized as an actual update.
    // Otherwise the object is just mutated in place
    const objects = Object.assign({}, state.clientObjects)
    delete objects[clientId]
    const idList = state.clientIds.filter((cid) => cid === clientId)
    state.clientObjects = objects
    state.clientIds = idList
  },
  UPDATE_CLIENT(state, updatedObj) {
    // create a shallow clone so when assigned it is recognized as an actual update.
    // Otherwise the object is just mutated in place
    const objects = Object.assign({}, state.clientObjects)
    const newValue = Object.assign({}, objects[updatedObj.clientId], updatedObj)
    objects[updatedObj.clientId] = newValue
    state.clientObjects = objects
    state.clientIds = [...state.clientIds]
  },
  SET_NEXT_TOKEN(state, nextToken) {
    state.nextToken = nextToken
  },
}

/** asynchronous actions against the module store and Tozny client list level resources */
const actions = {
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', 'Error: Unknown State', 'error')
    }
  },
  async initialize({ commit, dispatch, state, rootState }) {
    if (state.initialized) {
      return
    }
    if (rootState.tokens.status === 'initializing') {
      await dispatch('tokens/initialize', undefined, { root: true })
    }
    await dispatch('fetchNextPage')
    if (state.status !== 'error') {
      commit('INITIALIZED')
    }
  },
  async fetchNextPage({ commit, dispatch, state, rootState }) {
    await dispatch('transitionStatus', 'loading')
    try {
      const accountClient = rootState.account.accountClient
      const clientPage = await tozny.listAccountClients(
        accountClient,
        state.nextToken
      )
      const idList = [...state.clientIds]
      const objects = Object.assign({}, state.clientObjects)
      for (let client of clientPage.clients) {
        const cid = client.clientId
        // Filter out queen clients from display
        if (client.type == 'queen') {
          continue
        }
        // Cache the object indexed by client ID
        objects[cid] = client
        // If this client is in the list already, move them to the natural position
        const idx = idList.indexOf(cid)
        if (idx !== -1) {
          idList.splice(idx, 1)
        }
        idList.push(cid)
      }
      commit('SET_NEXT_TOKEN', clientPage.nextToken)
      commit('SET_CLIENTS', { idList, objects })
      dispatch('idleOrZero')
    } catch (e) {
      commit('SET_ERROR', { message: e.message, status: 'error' })
    }
  },
  async addClient({ commit, state }, client) {
    // if the client exists already, don't add it
    if (state.clientIds.indexOf(client.clientId) !== -1) {
      return
    }
    commit('ADD_CLIENT', client)
  },
  async updateClient({ commit, state, rootState }, client) {
    const idx = state.clientIds.indexOf(client.clientId)
    // ensure the client being updated is known
    if (idx === -1 && state.clientObjects[client.clientId]) {
      throw new Error('The client you are attempting to update was not found.')
    }
    // Currently the only update available is for enabled/disabled
    const accountClient = rootState.account.accountClient
    const set = await tozny.setClientEnabled(
      accountClient,
      client.clientId,
      client.enabled
    )
    client.enabled = set
    commit('UPDATE_CLIENT', client)
  },
  async removeClient({ commit }, clientId) {
    commit('REMOVE_CLIENT', clientId)
  },
  async registerClient(
    { commit, state, dispatch },
    { name, registrationToken }
  ) {
    const loadStatus = state.status === 'zero' ? 'zero.adding' : 'add.adding'
    await dispatch('transitionStatus', loadStatus)
    try {
      const newClient = await tozny.registerClient(name, registrationToken)
      await dispatch('addClient', newClient)
      await dispatch('idleOrZero')
    } catch (e) {
      const nextStatus = state.status === 'add.adding' ? 'add' : 'zero'
      commit('SET_ERROR', { message: e.message, status: nextStatus })
    }
  },
  async idleOrZero({ state, dispatch }) {
    // Note this is two because the account client always is present. If the account
    // client is filtered out, this should be adjusted.
    const nextStatus = state.clientIds.length < 1 ? 'zero' : 'idle'
    await dispatch('transitionStatus', nextStatus)
  },
}

export default {
  // namespace this modules actions, mutations, and getters under '/clients' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  modules,
  state,
  getters,
  actions,
  mutations,
}
