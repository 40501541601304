/**
 * tozny.js provides functions and constants for making HTTP API requests to Tozny services
 * @module tozny
 */

import Tozny from '@toznysecure/sdk/browser'
const TOZNY_API_HOST = global.API_URL
import InitiateWebAuthnChallengeData from '@toznysecure/sdk/types/initiateWebAuthnChallengeData'

export default {
  /**
   * registerAndBackupAccount registers a new account for accessing Tozny services via the UI or API.
   *
   * Throws error if
   * 	the account email is invalid
   * 	account registration fails
   * 	the account service is down
   *	the returned registration response is invalid json
   * if registration is successful,
   *	a PBKDF encrypted backup of the account's queen client will be stored in TozStore
   *	for retrieval upon account login for interacting with Tozny API services
   * <code>
   * account = tozny.registerAndBackupAccount({
   *  accountName: 'string',
   *  email: 'string',
   *  password: "validated strong password",
   * })
   * <code>
   *
   * @param {object} newAccount
   *
   * @returns {<@toznysecure/account-sdk.Client>}
   */
  async registerAndBackupAccount(accountService, newAccount) {
    return accountService.register(
      newAccount.accountName,
      newAccount.email,
      newAccount.password
    )
  },
  /**
   * login logins the user using the provided account credentials.
   *
   * Throws error if
   *	the login credentials are invalid
   *	the account service is down
   *	the returned login response is invalid json
   *
   * @param  {object} accountService: account service object for unauthenticated requests
   * @param  {object} account account credentials
   *
   * @returns {<@toznysecure/account-sdk.Client>}
   */
  async login(accountService, account) {
    return accountService.loginWithMFA(account.email, account.password)
  },

  async completeLogin(accountService, username, sigKeys, encKey, profile) {
    return accountService.completeLogin(username, sigKeys, encKey, profile)
  },

  async verifyTotp(accountService, username, sigKeys, challenge, totp) {
    return accountService.verifyTotp(username, sigKeys, challenge, totp)
  },

  async verifyWebAuthn(accountService, username, payload) {
    return accountService.verifyWebAuthn(username, payload)
  },

  async resetMFA(accountService, account) {
    return accountService.resetMFA(account.email, account.paperKey)
  },

  /**
   * loginWithPaperKey logs user in with account credentials with paperkey instead of password.
   *
   * Throws error if
   *	the login credentials are invalid
   *	the account service is down
   *	the returned login response is invalid json
   *
   * @param  {object} accountService: account service object for unauthenticated requests
   * @param  {object} account account credentials
   * @param  {string} type the type 'paper' to indicate paper key
   *
   * @returns {<@toznysecure/account-sdk.Client>}
   */
  async loginWithPaperKey(accountService, account) {
    return accountService.login(account.email, account.paperKey, 'paper')
  },
  async initiateRecoverWithEmail(accountService, email) {
    return accountService.initiateRecoverAccount(email)
  },
  async authenticateEmail(accountClient, id, otp) {
    return accountClient.verifyRecoverAccountChallenge(id, otp)
  },
  async recoverAccount(accountClient, newPassword, accountToken) {
    return accountClient.changeAccountPassword(newPassword, accountToken)
  },
  /**
   * checkAccountStatus checks the current account's billing status.
   *
   * Throws error if
   * 	the billing service is down
   * 	the returned response is invalid json
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated requests to the billing service
   *
   * @returns {<@toznysecure/account-sdk.types.AccountBillingStatue>}
   */
  async checkAccountStatus(accountClient) {
    return accountClient.billingStatus()
  },

  /**
   * updateAccountBilling updates the current account's billing status.
   *
   * Throws error if
   * 	the account service is down
   * 	the returned response is invalid json
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated requests to the account service
   *
   * @returns updated account and profile objects
   */
  async updateAccountBilling(accountClient, stripeToken) {
    return accountClient.updateAccountBilling(stripeToken)
  },

  /**
   * applyCouponCode attempts to apply a coupon code to the user's billing account
   *
   * Throws error if
   * 	the billing service is down
   * 	the coupon code is not accept, the response status is other than 200
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated requests to the billing service
   *
   * @returns response (no body)
   */
  async applyCouponCode(accountClient, couponCode) {
    return accountClient.addBillingCoupon(couponCode)
  },
  /** listAccountClients retrieves a paginated set of clients for the account */
  async listAccountClients(accountClient, nextToken, perPage = 50) {
    return accountClient.listClientInfo(nextToken, perPage)
  },
  /** getClientInfo retrieves client info for a single client in the account */
  async getClientInfo(accountClient, clientId) {
    return accountClient.getClientInfo(clientId)
  },
  /** setClientEnabled sets the enabled/disabled status for a single client in the account */
  async setClientEnabled(accountClient, clientId, enabled) {
    return accountClient.setClientEnabled(clientId, enabled)
  },
  /** credentialsForClient returns the decrypted client credentials record for the specified client */
  async credentialsForClient(queenClient, clientId) {
    const data = true
    const writer = 'all'
    const type = 'tozny.key_backup'
    const plain = {
      eq: {
        name: 'client',
        value: clientId,
      },
    }
    return queenClient.query(data, writer, null, type, plain).next()
  },
  /**
   * Edit the user's profile name (email also supported but not currently implemented)
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated account level requests to the Tozny API.
   *
   * @return new account and profile objects for user's account
   */
  async editProfile(accountClient, profile) {
    return accountClient.updateProfile(profile)
  },
  /**
   * Change the user's account password
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated account level requests to the Tozny API.
   *
   * @return
   */
  async changePassword(accountClient, { password, newPassword, type }) {
    return accountClient.changePassword({ password, newPassword, type })
  },
  /**
   * Get a list of tokens associate with an account
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated account level requests to the Tozny API.
   *
   * @return updated user profile
   */
  registrationTokens(accountClient) {
    return accountClient.registrationTokens()
  },

  /**
   * Write a new registration token for the account
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated account level requests to the Tozny API.
   * @param {string} name The name to associate with the new registration token.
   * @param {object} permissions. Permissions for the token such as what types of clients can be created with it.
   *
   * @return {Promise<@toznysecure/account-sdk.types.RegistrationToken>} The new token object.
   */
  writeRegistrationToken(accountClient, name, permissions) {
    return accountClient.newRegistrationToken(name, permissions)
  },

  /**
   * Remove a registration token associate with the account.
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated account level requests to the Tozny API.
   * @param {string} token The token to remove.
   *
   * @return {Promise<boolean>} true if the request complete successfully.
   */
  deleteRegistrationToken(accountClient, token) {
    return accountClient.deleteRegistrationToken(token)
  },

  /**
   * Get a list of webhooks associated with an account
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated account level requests to the Tozny API.
   *
   * @return {object} array of webhook objects - The available webhooks for the account.
   */
  webhooks(accountClient) {
    return accountClient.webhooks()
  },

  /**
   * Create a new webhook for the account
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated account level requests to the Tozny API.
   * @param {string} webhook The payload url
   * @param {object} triggers Api event triggers for the webhook
   *
   * @return { object } webhook The new webhook object
   */
  createWebhook(accountClient, { webhook_url, triggers }) {
    return accountClient.newWebhook(webhook_url, triggers)
  },

  /**
   * Delete a webhook associated with the account.
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated account level requests to the Tozny API.
   * @param {string} webhookId The webhook id to remove
   *
   * @return {Promise<boolean>} true if the request complete successfully.
   */
  deleteWebhook(accountClient, webhookId) {
    return accountClient.deleteWebhook(webhookId)
  },

  /**
   * subscribe subscribes the user to a pay-as-you-go plan.
   *
   * Throws error if
   * 	the billing service is down
   * 	the subscribe fails, returned response is not 200
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated requests to the billing service
   *
   * @returns response (no body)
   */
  async subscribe(accountClient) {
    return accountClient.subscribe()
  },
  /**
   * unsubscribe subscribes the user to a pay-as-you-go plan.
   *
   * Throws error if
   * 	the billing service is down
   * 	the unsubscribe fails, returned response is not 200
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated requests to the billing service
   *
   * @returns response (no body)
   */
  async unsubscribe(accountClient) {
    return accountClient.unsubscribe()
  },

  /**
   * gets aggregations for api calls made in a given time period
   *
   * Throws error if
   * 	the metrics service is down
   * 	the request is invalid
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated requests to the metric service
   *
   * @param {string} startTime - start time for api requests history
   * @param {string} endTime - end time for api requests history
   *
   * @returns daily aggregations of api calls in a time period response object
   */
  async getAggregations(accountClient, startTime, endTime) {
    return accountClient.getAggregations(startTime, endTime)
  },

  /**
   * gets api request history
   *
   * Throws error if
   * 	the metrics service is down
   * 	the request is invalid
   *
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated requests to the metric service
   *
   * @param {string} startTime - start time for api requests history
   * @param {string} endTime - end time for api requests history
   * @param {number} nextToken - token to retrieve next set of requests from history
   * @param {array} endpointsToExclude - array of path objects to exclude from request history
   * @returns api requests history response object
   */
  async getRequests(
    accountClient,
    startTime,
    endTime,
    nextToken,
    endpointsToExclude
  ) {
    return accountClient.getRequests(
      startTime,
      endTime,
      nextToken,
      endpointsToExclude
    )
  },
  /** verifyEmail verifies the account email associated with a Tozny account.
   *
   *  Displays error to user if
   *     request fails
   *     account service is down
   * @param {object} accountClient - {obj:@toznysecure/account-sdk.Client} account client object
   * for making authenticated requests to the metric service
   * @param {string} id - identifies the otp challenge
   * @param {string} otp - one time use code to verify
   *
   * @returns response
   */
  async verifyEmail(accountClient, id, otp) {
    return accountClient.verifyEmail(id, otp)
  },
  /** resendVerificationEmail requests the Tozny account verification email be resent
   */
  async resendVerificationEmail(accountClient) {
    return accountClient.resendVerificationEmail()
  },

  async registerClient(name, registrationToken) {
    const cryptoKeys = await Tozny.storage.generateKeypair()
    const signingKeys = await Tozny.storage.generateSigningKeypair()
    const info = await Tozny.storage.register(
      registrationToken,
      name,
      cryptoKeys,
      signingKeys,
      true,
      TOZNY_API_HOST
    )
    return {
      clientId: info.clientId,
      name: info.name,
      hasBackup: true,
      enabled: true,
    }
  },
  async fetchBackupCredentials(queenClient, clientId) {
    const data = true
    const writer = clientId
    const type = 'tozny.key_backup'
    const plain = {
      eq: {
        name: 'client',
        value: clientId,
      },
    }
    const backupQuery = await queenClient
      .query(data, writer, null, type, plain)
      .next()
    if (backupQuery.length < 1) {
      throw new Error(`Unable to recover credentials for ${clientId}`)
    }
    const clientCredentials = backupQuery[0].data
    // Backup credential entries are encoded as JSON, so they have literal
    // quote marks in the strings. This detects them and strips them out so
    // the object can be properly used.
    for (let key in clientCredentials) {
      if (clientCredentials[key].indexOf('"') !== -1) {
        clientCredentials[key] = JSON.parse(clientCredentials[key])
      }
    }
    return clientCredentials
  },
  /**
   * Creates an actual Tozny client from a raw config object.
   *
   * Doing this as a method keeps the Tozny SDK here instead of also injecting
   * it into the store directly.
   *
   * This method also validate the client is working by fetching the client's own
   * information and checking to ensure the public keys match.
   *
   * @param {Object} credentials A set of client credentials in object form.
   * @return {Object} A validated instantiated Tozny client
   */
  async createClient(credentials) {
    const client = new Tozny.storage.Client(
      Tozny.storage.Config.fromObject(credentials)
    )
    let info
    try {
      // fetch own info to validate credentials are actually valid before caching
      info = await client.clientInfo(client.config.clientId)
    } catch (e) {
      throw new Error('Provided client credentials are invalid')
    }
    if (info.publicKey.curve25519 !== client.config.publicKey) {
      throw new Error(
        `public key mismatch for client ${client.config.clientId}`
      )
    }
    return client
  },
  /**
   * Get a list of groups associated with an account
   *
   * @param {object} queenClient
   * for making authenticated account level requests to the Tozny API.
   *
   * @return list of groups for the account
   */
  async listGroups(queenClient, nextToken, perPage = 50) {
    return await queenClient.listGroups(null, [], nextToken, perPage)
  },
  /**
   * Get a specific group by groupID
   *
   * @param {object} queenClient
   * @param {string} groupID  for making authenticated account level requests to the Tozny API.
   *
   * @return group with groupID
   */
  async getSingleGroup(queenClient, groupID) {
    return await queenClient.readGroup(groupID)
  },
  /**
   * Create a new group with specified group name
   *
   * @param {object} queenClient
   * @param {object} group  contains at least the groupName for the new group.
   *
   * @return groupMembership which contains info about the group
   */
  async createGroup(queenClient, group) {
    return await queenClient.createGroup(group.groupName, [], group.description)
  },
  /**
   * addGroupMembers adds a group member to the group
   *
   * @param {object} queenClient
   * @param {object} groupID  the ID of the group to add the members to.
   * @param {object} groupMembers object containing the clientID and capabilities for the new members.
   *
   * @return groupMembers which contains information about newly added group members
   */
  async addGroupMembers(queenClient, groupID, groupMembers) {
    return queenClient.addGroupMembers(groupID, groupMembers)
  },
  /**
   * Delete the group with specified groupID
   *
   * @param {object} queenClient
   * @param {object} groupID  the ID of the group to delete.
   *
   * @return {Promise<boolean>} true if the request complete successfully.
   */
  async deleteGroup(queenClient, groupID) {
    return queenClient.deleteGroup(groupID)
  },
  /**
   * Delete group members from a specified group
   *
   * @param {object} queenClient
   * @param {object} groupID  the ID of the group
   * @param {Array} clientIDs the ID's of the clients to remove
   *
   * @return {Promise<boolean>} true if the request complete successfully.
   */
  async deleteMembers(queenClient, groupID, clientIDs) {
    return queenClient.removeGroupMembers(groupID, clientIDs)
  },
  /**
   * List group members from a specified group
   *
   * @param {object} queenClient
   * @param {object} groupID  the ID of the group.
   *
   * @return {Object} The list of group members and their capabilities
   */
  async listMembers(queenClient, groupID, nextToken = null) {
    return queenClient.listGroupMembers(groupID, nextToken)
  },

  async getMFA(accountClient) {
    return accountClient.getMFA()
  },

  async getQRInfo(accountClient) {
    return accountClient.initiateTotp()
  },

  async registerTotp(accountClient, data) {
    return accountClient.registerTotp(data)
  },

  async deleteMFA(accountClient, id) {
    return accountClient.deleteMFA(id)
  },

  async initiateWebAuthn(accountClient) {
    const response = await accountClient.initiateWebAuthn()
    return InitiateWebAuthnChallengeData.decode(response)
  },

  async registerWebAuthnDevice(accountClient, publicKeyCredential, deviceName) {
    const data = {
      mfa_devices: {
        webauthn:
          InitiateWebAuthnChallengeData.convertPublicKeyCredentialToRegistrationData(
            publicKeyCredential,
            deviceName
          ),
      },
    }
    return accountClient.registerWebAuthnDevice(data)
  },
}
