import errors from '@toznysecure/sdk/types/errors'

/** Whether or not registration is disabled. Default: false. Only set at initialization */
export const REGISTRATION_DISABLED =
  typeof global.REGISTRATION_DISABLED === 'string' &&
  global.REGISTRATION_DISABLED.toLowerCase() === 'true'

export const STRIPE_ENABLED =
  typeof global.STRIPE_ENABLED === 'string' &&
  global.STRIPE_ENABLED.toLowerCase() === 'true'

export function validatePassword(password) {
  let capsCount, smallCount, numberCount, symbolCount
  if (password.length < 10) {
    return 'Password must be at least 10 characters.'
  } else {
    capsCount = (password.match(/[A-Z]/g) || []).length
    smallCount = (password.match(/[a-z]/g) || []).length
    numberCount = (password.match(/[0-9]/g) || []).length
    symbolCount = (password.match(/\W/g) || []).length
    if (capsCount < 1) {
      return 'Password must contain a capital letter.'
    } else if (smallCount < 1) {
      return 'Password must contain a lowercase letter.'
    } else if (numberCount < 1) {
      return 'Password must contain a number.'
    } else if (symbolCount < 1) {
      return 'Password must contain a symbol.'
    }
  }
  return ''
}

export function checkStatus(response, message) {
  if (!response.ok) {
    const errorMessage = message || response.statusText
    throw new Error(errorMessage)
  }
}

export function handleMFAError(e, defaultMessage) {
  // eslint-disable-next-line no-console
  console.error(e)

  switch (e.name) {
    // `NotAllowedError` is the browser's way of telling the application that either the user or browser has
    // denied the application permissions. With regards to MFA registration errors, `NotAllowedError` is thrown
    // in Chrome when the prompt to choose either a security device or "this device" (e.g. Apple Touch ID) is aborted.
    // In Safari, `NotAllowedError` is thrown when attempting to request a user authenticate themselves without the user
    // having authorized Safari to request hardware security keys. Hence the mildly ambiguous error message below.
    case 'NotAllowedError':
      return 'Security key action was canceled or failed to initiate. Please try again.'
    case 'AbortError':
      return 'Security key action aborted.'
    case 'RequestError':
      if (!e.response) {
        break
      }
      if (e.response.status === 404) {
        return 'Device already removed.'
      }
      if (e.response.status === 501) {
        return 'Unauthorized to perform security key request.'
      }
      if (e.response.status === 500) {
        return 'Security key request failed due to internal server error.'
      }
      if (e.response.status === 503) {
        return 'Service is unavailable. Cannot update security key.'
      }
      break
  }
  return defaultMessage || 'An unexpected error occurred. Please try again.'
}

export function isUnauthorized(error) {
  return (
    error instanceof errors.general.RequestError &&
    error.response.status === 401
  )
}
