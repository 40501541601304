/**
 * group implements user level actions on a single Tozny group resource
 * @module group
 */

import tozny from '../../api/tozny'
import groupMembers from './groupMembers'
const statuses = ['initializing', 'error', 'active']

let activeGroup = undefined

/** submodules */
const modules = {
  groupMembers,
}
/** initial group state */
const state = {
  status: 'initializing',
  group: {},
  groupID: '',
  errorMessage: '',
}

const getters = {
  storage(state) {
    if (!(state.status === 'active' && activeGroup)) {
      return undefined
    }
    return activeGroup
  },
  groupObject(state, rootGetters) {
    return rootGetters['groups/getGroup'](state.groups.groupID)
  },
  name(state, getters) {
    const obj = getters.groupObject
    return obj ? obj.groupName : ''
  },
  getGroup(state) {
    return state.group
  },
}

const mutations = {
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_ERROR(state, { message, status }) {
    state.errorMessage = message
    state.status = status
  },
  CLEAR_ERROR(state) {
    state.errorMessage = ''
  },
  SET_GROUP(state, group) {
    state.group = group
  },
  SET_GROUP_ID(state, groupID) {
    state.groupID = groupID
  },
  CLEAR(state) {
    state.group = {}
    state.errorMessage = ''
    activeGroup = undefined
  },
}

/** asynchronous actions against the module store and single Tozny group level resources */
const actions = {
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', 'Error: Unknown State', 'error')
    }
  },
  async initialize({ dispatch }, groupID) {
    dispatch('transitionStatus', 'initializing')
    await dispatch('load', groupID)
  },
  async load({ commit, state, rootGetters }, groupID) {
    if (groupID === state.group.groupID) {
      return
    }
    commit('CLEAR')
    const queenClient = rootGetters['account/queenClient']
    const fetched = await tozny.getSingleGroup(queenClient, groupID)
    if (!fetched) {
      commit('SET_ERROR', {
        message: `A group with ID ${groupID} was not found`,
        status: 'error',
      })
      return
    }
    let group = fetched
    commit('SET_GROUP', group)
  },
}

export default {
  // namespace this modules actions, mutations, and getters under '/group' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  modules,
  getters,
  actions,
  mutations,
}
