/**
 * State associated with metrics.
 */

/**
 * state and actions surrounding Tozny metrics service
 * @module metrics
 */
import tozny from '../../api/tozny'

/** initial metrics state */
const state = {
  requests: [],
  includeAdminRequests: true,
  requestsNextToken: 0,
  requestCount: null,
  currentMetrics: [],
  previousMetrics: [],
  loadingRequests: false,
  loadingMetrics: false,
  requestsErrorMessage: '',
  metricsErrorMessage: '',
}

/** synchronous mutations of metrics state */
const mutations = {
  SET_REQUESTS(state, requests) {
    state.requests = requests
  },
  SET_NEXT_TOKEN(state, requestsNextToken) {
    state.requestsNextToken = requestsNextToken
  },
  SET_INCLUDE_ADMIN_REQUESTS(state, bool) {
    state.includeAdminRequests = bool
  },
  SET_REQUEST_COUNT(state, requestCount) {
    state.requestCount = requestCount
  },
  SET_CURRENT_METRICS(state, metrics) {
    state.currentMetrics = metrics
  },
  SET_PREVIOUS_METRICS(state, metrics) {
    state.previousMetrics = metrics
  },
  SET_REQUESTS_ERROR(state, error) {
    state.requestsErrorMessage = error
  },
  CLEAR_REQUESTS_ERROR(state) {
    state.requestsErrorMessage = ''
  },
  LOADING_REQUESTS_TRUE(state) {
    state.loadingRequests = true
  },
  LOADING_REQUESTS_FALSE(state) {
    state.loadingRequests = false
  },
  LOADING_METRICS_TRUE(state) {
    state.loadingMetrics = true
  },
  LOADING_METRICS_FALSE(state) {
    state.loadingMetrics = false
  },
  SET_METRICS_ERROR(state, error) {
    state.metricsErrorMessage = error
  },
  CLEAR_METRICS_ERROR(state) {
    state.metricsErrorMessage = ''
  },
}

/** computed values for metrics state */
const getters = {
  hasMoreRequests: (state) => {
    // TODO:  Try this with a failed request.
    // TODO:  Consider a failure where backend someone returns [].
    return state.requestsNextToken !== 0 && state.requests.length !== 0
  },
}

/**
 * Callable state transitions that facilitate async actions and state transitions
 */
const actions = {
  // Sets the state includeAdminRequests value
  async changeAdminRequests({ commit }, bool) {
    commit('SET_INCLUDE_ADMIN_REQUESTS', bool)
    commit('SET_NEXT_TOKEN', 0)
  },
  /**
   * Gets this account's api request history
   */
  async getRequests({ commit, state, rootState }) {
    commit('CLEAR_REQUESTS_ERROR')
    commit('LOADING_REQUESTS_TRUE')
    // calculate a date range
    const date = new Date()
    const endTime = date.toISOString()
    const past = new Date()
    past.setDate(past.getDate() - 31)
    const startTime = past.toISOString()
    const accountClient = rootState.account.accountClient
    const adminEndpoints = [
      { path: '/v1/account/tokens' },
      { path: '/v1/account/profile' },
      { path: '/v1/account/profile/stats' },
      { path: '/v1/metric/requests' },
      { path: '/v1/account/e3db/clients' },
      { path: '/v1/account/profile/meta' },
      { path: '/v1/metric/aggregations' },
      { path: '/v1/account/auth' },
      { path: '/v1/metric/requests/aggregations' },
      { path: '/v1/metric/' },
      { path: '/v1/client/admin?next=0&limit=50' },
    ]
    const endpointsToExclude = state.includeAdminRequests ? [] : adminEndpoints
    try {
      const requests = await tozny.getRequests(
        accountClient,
        startTime,
        endTime,
        state.requestsNextToken,
        endpointsToExclude
      )
      if (state.requestsNextToken === 0) {
        commit('SET_REQUESTS', requests.results ? requests.results : [])
      } else {
        const requestsClone = state.requests.map((obj) =>
          Object.assign({}, obj)
        )
        commit('SET_REQUESTS', requestsClone.concat(requests.results))
      }
      commit('SET_NEXT_TOKEN', requests.next_token)
      commit('LOADING_REQUESTS_FALSE')
    } catch (err) {
      const errMsg = err.message
        ? `Cannot retrieve request history. Error: ${err.message}`
        : 'Cannot retrieve request history.'
      commit('SET_REQUESTS_ERROR', errMsg)
      commit('LOADING_REQUESTS_FALSE')
    }
  },
  /**
   * Gets this account's metrics
   */
  async getMetrics({ commit, rootState }) {
    commit('LOADING_METRICS_TRUE')
    commit('CLEAR_METRICS_ERROR')

    // calculate a date range
    const date = new Date()
    // current date
    const currentDate = date.toISOString()
    // back to 31 days ago
    const daysAgo30 = new Date()
    daysAgo30.setDate(daysAgo30.getDate() - 31)
    const daysPast30 = daysAgo30.toISOString()
    const accountClient = rootState.account.accountClient
    // back to 62 days ago
    const daysAgo60 = new Date()
    daysAgo60.setDate(daysAgo60.getDate() - 62)
    const daysPast60 = daysAgo60.toISOString()

    try {
      const currentMetrics = await tozny.getAggregations(
        accountClient,
        daysPast30,
        currentDate
      )
      const currentMetricsResults = currentMetrics.results
      // no results will return null
      if (currentMetricsResults) {
        commit('SET_CURRENT_METRICS', currentMetricsResults.reverse())
      }
      const previousMetrics = await tozny.getAggregations(
        accountClient,
        daysPast60,
        daysPast30
      )
      const previousMetricsResults = previousMetrics.results
      // no results will return null
      if (previousMetricsResults) {
        commit('SET_PREVIOUS_METRICS', previousMetricsResults.reverse())
      }
      commit('LOADING_METRICS_FALSE')
    } catch (err) {
      const errMsg = err.message
        ? `Cannot retrieve metrics. Error: ${err.message}`
        : 'Cannot retrieve metrics.'
      commit('SET_METRICS_ERROR', errMsg)
      commit('LOADING_METRICS_FALSE')
    }
  },
}

export default {
  // namespace this modules actions, mutations, and getters under '/metrics' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
