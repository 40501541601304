/**
 * State associated with webhooks.
 */

/**
 * state and actions surrounding Tozny webhooks
 * @module webhooks
 */
import tozny from '../../api/tozny'

/** initial webhooks state */
const state = {
  status: 'initializing',
  webhooks: [],
  errorMessage: '',
  deleteQueue: {},
  activeWebhook: null,
}

/** synchronous mutations of webhooks state */
const mutations = {
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_WEBHOOKS(state, { webhooks }) {
    state.webhooks = webhooks
  },
  SET_ERROR(state, { error, status }) {
    state.errorMessage = error
    state.status = status
  },
  CLEAR_ERROR(state) {
    state.errorMessage = ''
  },
  SET_DELETE_QUEUE(state, { webhook }) {
    state.deleteQueue = webhook
  },
  CLEAR_DELETE_QUEUE() {
    state.deleteQueue = {}
  },
  SET_ACTIVE_WEBHOOK(state, webhook) {
    state.activeWebhook = webhook
  },
}

const statuses = [
  'initializing',
  'loading',
  'zero',
  'zero.adding',
  'idle',
  'idle.delete',
  'add',
  'adding',
  'show',
  'show.delete',
  'globalError',
]

/**
 * Callable state transitions that facilitate async actions and state transitions
 */
const actions = {
  async setActiveWebhook({ commit }, webhook) {
    commit('SET_ACTIVE_WEBHOOK', webhook)
  },
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', 'Error: Unknown State', 'globalError')
    }
  },
  /**
   * Shows different webhooks
   */
  async showWebhook({ commit }, webhookId) {
    commit('SET_SHOWING_WEBHOOK_ID', webhookId)
  },

  /**
   * Syncs the state machine with the application state, fetching as necessary.
   * @param {context} param0 The vuex state context
   */
  async initialize({ dispatch, state }) {
    // Only act on initializing state
    if (state.status !== 'initializing') {
      return
    }
    // Determine if initial fetch is necessary
    if (!state.webhooks.length) {
      await dispatch('transitionStatus', 'loading')
      await dispatch('loadWebhooks')
    } else {
      await dispatch('transitionStatus', 'idle')
    }
  },

  async loadWebhooks({ commit, dispatch, rootState }) {
    try {
      const accountClient = rootState.account.accountClient
      const webhooksResponse = await tozny.webhooks(accountClient)
      const webhooks = webhooksResponse.hooks
      if (webhooks) {
        commit('SET_WEBHOOKS', { webhooks })
      }
      const transition = webhooks ? 'idle' : 'zero'
      await dispatch('transitionStatus', transition)
    } catch (e) {
      const error = e.message
      commit('SET_ERROR', { error, status: 'globalError' })
    }
  },
  async createWebhook(
    { commit, dispatch, state, rootState },
    { webhook_url, triggers }
  ) {
    dispatch('transitionStatus', 'adding')
    try {
      const accountClient = rootState.account.accountClient
      const newWebhook = await tozny.createWebhook(accountClient, {
        webhook_url,
        triggers,
      })
      const webhooks = [...state.webhooks, newWebhook]
      commit('SET_WEBHOOKS', { webhooks })
      dispatch('transitionStatus', 'idle')
    } catch (e) {
      const status = 'add'
      const error = e.message
      commit('SET_ERROR', { error, status })
    }
  },
  async deleteWebhook({ commit, dispatch, state, rootState }, webhook) {
    const original = state.webhooks.slice(0)
    const modified = state.webhooks
      .slice(0)
      .filter((h) => h.webhook_id !== webhook.webhook_id)
    commit('SET_WEBHOOKS', { webhooks: modified })
    const transition = modified.length > 0 ? 'idle' : 'zero'
    await dispatch('transitionStatus', transition)

    try {
      const accountClient = rootState.account.accountClient
      await tozny.deleteWebhook(accountClient, webhook.webhook_id)
    } catch (e) {
      commit('SET_WEBHOOKS', { webhooks: original })
      const error = e.message
      commit('SET_ERROR', { error, status: 'show' })
    }
  },
  async enqueueDelete({ commit, dispatch }, webhook) {
    commit('SET_DELETE_QUEUE', { webhook })
    await dispatch('transitionStatus', 'show.delete')
  },
  async cancelDeleteQueue({ commit, dispatch }) {
    commit('CLEAR_DELETE_QUEUE')
    await dispatch('transitionStatus', 'show')
  },
}

export default {
  // namespace this modules actions, mutations, and getters under '/webhooks' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  actions,
  mutations,
}
