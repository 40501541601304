/**
 * records implements user level actions of Tozny record level resources
 * @module records
 */
import { types } from '@toznysecure/sdk/browser'

let query = undefined

const statuses = [
  'initializing',
  'idle',
  'loading',
  'adding',
  'add.error',
  'error',
]
const singleStatuses = ['loading', 'idle', 'updating', 'error']
const addStatuses = ['idle', 'adding']

/** initial client state */
const state = {
  status: 'initializing',
  recordOrder: [],
  recordCache: {},
  errorMessage: '',
  initialized: false,
  hasMore: true,
  currentClient: '',
  singleStatus: 'loading',
  singleError: '',
  recordId: undefined,
  addStatus: 'idle',
  addError: '',
}

/** cache-able getters for the current users Tozny record list level resources */
const getters = {
  storage(state, getters, rootState, rootGetters) {
    return rootGetters['clients/client/storage']
  },
  records(state) {
    return state.recordOrder.map((rid) => state.recordCache[rid])
  },
  record(state) {
    // If we have a record ID and it is in the cache, return the cached record.
    if (state.recordId && !!state.recordCache[state.recordId]) {
      return state.recordCache[state.recordId]
    }
    // Return an empty object with the correct record shape. when deleting records
    // in particular, modules expect record state to be in tact until the delete is
    // completed -- this ensures they have valid object keys to reach for and keeps
    // the resulting logic much more simple.
    return {
      meta: {
        recordId: '',
        type: '',
        plain: {},
      },
      data: {},
    }
  },
}

/** synchronous mutations of record list state */
const mutations = {
  INITIALIZED(state) {
    state.initialized = true
  },
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_CLIENT(state, client) {
    state.currentClient = client
  },
  SET_ERROR(state, { message, status }) {
    state.errorMessage = message
    state.status = status
  },
  CLEAR_ERROR(state) {
    state.errorMessage = ''
  },
  ADD_RECORDS(state, records) {
    const order = [...state.recordOrder]
    const cache = Object.assign({}, state.recordCache)
    for (let record of records) {
      const recordId = record.meta.recordId
      const index = order.indexOf(recordId)
      // If this record is already in the order, remove so it is added at the
      // natural position.
      if (index !== -1) {
        order.splice(index, 1)
      }
      order.push(recordId)
      cache[recordId] = record
    }
    state.recordCache = cache
    state.recordOrder = order
  },
  UPDATE_RECORD(state, record) {
    const order = [...state.recordOrder]
    const cache = Object.assign({}, state.recordCache)
    const recordId = record.meta.recordId
    const index = order.indexOf(recordId)
    // If this record missing from the order, add it.
    if (index === -1) {
      order.push(recordId)
    }
    // Update the record cache
    cache[recordId] = record
    // Let vuex know about the updates.
    state.recordCache = cache
    state.recordOrder = order
  },
  REMOVE_RECORD(state, recordId) {
    const order = [...state.recordOrder]
    const cache = Object.assign({}, state.recordCache)
    const index = order.indexOf(recordId)
    // If the record exists in the order, remove it
    if (index !== -1) {
      order.splice(index, 1)
    }
    // Remove the record from the cache
    delete cache[recordId]
    // Let vuex know about the updates.
    state.recordCache = cache
    state.recordOrder = order
  },
  SET_RECORDS(state, { cache, order }) {
    state.recordCache = cache
    state.recordOrder = order
  },
  CLEAR_RECORDS(state) {
    state.status = 'initializing'
    state.recordOrder = []
    state.recordCache = {}
    state.initialized = false
    state.currentClient = ''
    state.hasMore = true
    query = undefined
  },
  NO_MORE(state) {
    state.hasMore = false
  },
  SET_SINGLE_STATUS(state, next) {
    state.singleStatus = next
    state.singleError = ''
  },
  SET_SINGLE_ERROR(state, { message, status }) {
    state.singleError = message
    state.singleStatus = status
  },
  SELECT_RECORD(state, recordId) {
    state.recordId = recordId
  },
  CLEAR_RECORD(state) {
    state.recordId = undefined
  },
  SET_ADD_STATUS(state, next) {
    state.addStatus = next
    state.addError = ''
  },
  SET_ADD_ERROR(state, { message, status }) {
    state.addError = message
    state.addStatus = status
  },
  CLEAR_ADD_ERROR(state) {
    state.addError = ''
    state.addStatus = 'idle'
  },
}

/** asynchronous actions against the module store and Tozny record list level resources */
const actions = {
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', 'Error: Unknown State', 'error')
    }
  },

  async singleStatus({ commit }, status) {
    if (singleStatuses.includes(status)) {
      commit('SET_SINGLE_STATUS', status)
    } else {
      commit('SET_SINGLE_ERROR', 'Error: Unknown State', 'error')
    }
  },

  async addStatus({ commit }, status) {
    if (addStatuses.includes(status)) {
      commit('SET_ADD_STATUS', status)
    } else {
      commit('SET_ADD_ERROR', 'Error: Unknown State', 'idle')
    }
  },

  async initialize({ commit, state, getters, dispatch }) {
    if (!getters.storage) {
      commit('SET_ERROR', {
        message: 'No storage client available',
        status: 'error',
      })
      return
    }
    if (getters.storage.config.clientId === state.currentClient) {
      return
    }
    if (state.status === 'loading') {
      return
    }
    commit('CLEAR_RECORDS')
    commit('CLEAR_ERROR')
    await dispatch('fetchNextPage')
    if (state.status === 'error') {
      return
    }
    commit('SET_CLIENT', getters.storage.config.clientId)
    commit('INITIALIZED')
    await dispatch('transitionStatus', 'idle')
  },

  async fetchNextPage({ commit, dispatch, getters }) {
    commit('CLEAR_ERROR')
    await dispatch('transitionStatus', 'loading')
    try {
      if (!query) {
        query = getters.storage.query(false, 'all')
      }
      const nextPage = await query.next()
      commit('ADD_RECORDS', nextPage)
      // If records per page customization is allowed, this length check should
      // be updated to use that value rather than a hard coded 100.
      if (query.done || nextPage.length < 100) {
        commit('NO_MORE')
      }
      await dispatch('transitionStatus', 'idle')
    } catch (e) {
      commit('SET_ERROR', { message: e.message, status: 'error' })
    }
  },

  async selectRecord({ commit, state, getters, dispatch }, recordId) {
    // First, just ensure the storage client is available for fetching.
    // If the storage client is not available, the client is inactive and this
    // method should not get called. Seeing this error will indicate a
    // bootstrapping issue.
    if (!getters.storage) {
      commit('SET_SINGLE_ERROR', {
        message: 'No storage client available',
        status: 'error',
      })
      return
    }
    // If the currently selected record matches the record to select, do nothing.
    if (state.recordId === recordId) {
      await dispatch('singleStatus', 'idle')
      return
    }
    // If the record is already in the cached with data attached, use the cached version.
    const record = state.recordCache[recordId]
    if (
      typeof record === 'object' &&
      record.data &&
      typeof record.data === 'object' &&
      Object.keys(record.data).length > 0
    ) {
      // Record is cached with data. Select it and return.
      commit('UPDATE_RECORD', record)
      commit('SELECT_RECORD', recordId)
      await dispatch('singleStatus', 'idle')
      return
    }
    // If the record is not in the cache, or is missing data, perform a fetch
    await dispatch('singleStatus', 'loading')
    try {
      const record = await getters.storage.readRecord(recordId)
      commit('UPDATE_RECORD', record)
      commit('SELECT_RECORD', recordId)
      dispatch('singleStatus', 'idle')
    } catch (e) {
      commit('SET_SINGLE_ERROR', { message: e.message, status: 'error' })
    }
  },
  async addRecord({ commit, dispatch, getters }, { type, data, meta }) {
    await dispatch('addStatus', 'adding')
    try {
      const record = await getters.storage.writeRecord(type, data, meta)
      commit('ADD_RECORDS', [record])
      commit('SELECT_RECORD', record.meta.recordId)
      await dispatch('addStatus', 'idle')
    } catch (e) {
      commit('SET_ADD_ERROR', { message: e.message, status: 'idle' })
    }
  },
  async updateRecord({ commit, getters, dispatch }, { type, data, meta }) {
    await dispatch('singleStatus', 'updating')
    const record = await types.Record.decode(getters.record.serializable())
    record.meta.type = type
    record.meta.plain = meta
    record.data = data
    try {
      const updated = await getters.storage.updateRecord(record)
      commit('UPDATE_RECORD', updated)
      dispatch('singleStatus', 'idle')
    } catch (e) {
      commit('SET_SINGLE_ERROR', { message: e.message, status: 'error' })
    }
  },
  async deleteRecord({ commit, state, getters }, recordId) {
    // save out the pre-delete order and cache in case it goes wrong
    const originalOrder = state.recordOrder
    const originalCache = state.recordCache
    // fetch the record from cache to facilitate 'safe' delete.
    const record = state.recordCache[recordId]
    if (record === undefined) {
      commit('SET_SINGLE_ERROR', {
        message: `Unable to remove ${recordId}: Not found.`,
        status: 'error',
      })
    }
    // If this record is selected, deselect it
    if (recordId === state.recordId) {
      commit('CLEAR_RECORD')
    }
    // Immediately remove the record from state -- better UX for the 99.99% use-case
    commit('REMOVE_RECORD', recordId)
    // Delete from the server
    try {
      await getters.storage.deleteRecord(
        record.meta.recordId,
        record.meta.version
      )
    } catch (e) {
      // If the server delete fails, reset state and report the failure.
      commit('SET_ERROR', { message: e.message, status: 'error' })
      commit('SET_RECORDS', { cache: originalCache, order: originalOrder })
    }
  },
}

export default {
  // namespace this modules actions, mutations, and getters under '/records' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
