<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'app',
  methods: {
    ...mapActions('account', ['loadAccountFromSession']),
  },
  created: function () {
    this.loadAccountFromSession()
  },
}
</script>
<style lang="scss">
@import '@/scss/app.scss';
</style>
