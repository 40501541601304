/**
 * client implements user level actions of single Tozny client level resources
 * @module client
 */
import tozny from '../../api/tozny'
import records from './records'

const statuses = ['initializing', 'error', 'disabled', 'locked', 'active']

// Since complex objects and Vuex modules don't really get along that well
// the actual client objects are not stored in the vuex module itself. However
// we do want to keep track of the list of previously activated clients. To this
// end the caches are set up here. The complex object state is managed in the
// cache while object access is managed via the store module.
let activeClient = undefined
let clientCache = {}

/** submodules */
const modules = {
  records,
}

/** initial client state */
const state = {
  status: 'initializing',
  clientId: '',
  errorMessage: '',
  enabledChanging: false,
  activating: false,
}

/** cache-able getters for the current users single Tozny client level resources */
const getters = {
  storage(state) {
    if (!(state.status === 'active' && activeClient)) {
      return undefined
    }
    return activeClient
  },
  clientObject(state, getters, rootState, rootGetters) {
    return rootGetters['clients/getClient'](state.clientId)
  },
  name(state, getters) {
    const obj = getters.clientObject
    return obj ? obj.name : ''
  },
  enabled(state, getters) {
    const obj = getters.clientObject
    return obj ? obj.enabled : true
  },
  isQueen(state, getters, rootState, rootGetters) {
    const queen = rootGetters['account/queenClient']
    if (!queen) {
      return false
    }
    return queen.config.clientId === state.clientId
  },
  hasBackup(state, getters) {
    const obj = getters.clientObject
    return obj ? obj.hasBackup : false
  },
  credentials(state, getters) {
    if (!state.status === 'active') {
      return {}
    }
    const camelConfig = getters.storage
    if (!camelConfig) {
      return {}
    }
    const snakeConfig = {}
    for (let key in camelConfig.config) {
      const snakeKey = key
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase()
      snakeConfig[snakeKey] = camelConfig.config[key]
    }
    snakeConfig.version = String(snakeConfig.version)
    if (!('email' in snakeConfig)) {
      snakeConfig.client_email = ''
    }
    return snakeConfig
  },
}

/** synchronous mutations of single client state */
const mutations = {
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_ERROR(state, { message, status }) {
    state.errorMessage = message
    state.status = status
  },
  CLEAR_ERROR(state) {
    state.errorMessage = ''
  },
  SET_CLIENT(state, client) {
    state.clientId = client.clientId
  },
  CLEAR(state) {
    state.clientId = ''
    state.errorMessage = ''
    state.enabledChanging = false
    state.activating = false
    activeClient = undefined
  },
  SET_ENABLED_CHANGING(state, changing) {
    state.enabledChanging = changing
  },
  SET_ACTIVATING(state, activating) {
    state.activating = activating
  },
}

/** asynchronous actions against the module store and single Tozny client level resources */
const actions = {
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', 'Error: Unknown State', 'error')
    }
  },
  async initialize({ dispatch }, clientId) {
    dispatch('transitionStatus', 'initializing')
    await dispatch('load', clientId)
    await dispatch('activate')
  },
  async load({ commit, state, dispatch, rootState, rootGetters }, clientId) {
    if (clientId === state.clientId) {
      return
    }
    commit('CLEAR')
    let client = await rootGetters['clients/getClient'](clientId)
    if (!client) {
      const accountClient = rootState.account.accountClient
      const fetched = await tozny.getClientInfo(accountClient, clientId)
      if (!fetched) {
        commit('SET_ERROR', {
          message: `A client with ID ${clientId} was not found`,
          status: 'error',
        })
        return
      }
      await dispatch('clients/addClient', fetched, { root: true })
      client = fetched
    }
    commit('SET_CLIENT', client)
  },
  async activate(
    { commit, dispatch, rootGetters, getters, state },
    clientDetails = {}
  ) {
    commit('SET_ACTIVATING', true)
    // Set disabled if the client is so
    if (!getters.enabled) {
      await dispatch('transitionStatus', 'disabled')
      return
    }
    // Do not attempt to activate the client if:
    // - The client is already active
    // - A global loading error has ocurred
    if (['active', 'error'].indexOf(state.status) !== -1) {
      return
    }

    commit('CLEAR_ERROR')
    const clientId = state.clientId

    // If the client is already available in the cache, activate it and stop processing
    if (clientCache[clientId]) {
      activeClient = clientCache[clientId]
      await dispatch('transitionStatus', 'active')
      commit('SET_ACTIVATING', false)
      return
    }
    // If client details were not passed and there is no backup, lock the client and stop processing
    if (!(Object.keys(clientDetails).length > 0 || getters.hasBackup)) {
      await dispatch('transitionStatus', 'locked')
      commit('SET_ACTIVATING', false)
      return
    }

    try {
      // Duck type a bit to determine if we have credentials (the API Key ID and
      // public key are present) or need to fetch them.
      let clientCredentials
      if (
        (clientDetails.api_key_id || clientDetails.apiKeyId) &&
        (clientDetails.public_key || clientDetails.publicKey)
      ) {
        clientCredentials = clientDetails
      } else {
        const queenClient = rootGetters['account/queenClient']
        clientCredentials = await tozny.fetchBackupCredentials(
          queenClient,
          clientId
        )
      }
      // Turn the credentials into an actual Tozny client, cache and activate
      const client = await tozny.createClient(clientCredentials)
      clientCache[clientId] = client
      activeClient = client
      await dispatch('transitionStatus', 'active')
    } catch (e) {
      commit('SET_ERROR', { message: e.message, status: 'locked' })
    }
    commit('SET_ACTIVATING', false)
  },
  async setEnabled({ state, commit, dispatch, getters }, enabled) {
    if (getters.enabled === enabled) {
      return
    }
    commit('SET_ENABLED_CHANGING', true)
    await dispatch(
      'clients/updateClient',
      { clientId: state.clientId, enabled },
      { root: true }
    )
    await dispatch('activate')
    commit('SET_ENABLED_CHANGING', false)
  },
}

export default {
  // namespace this modules actions, mutations, and getters under '/client' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  modules,
  state,
  getters,
  actions,
  mutations,
}
